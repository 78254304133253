import React, { ReactElement, useMemo } from 'react'
import { Grid, Box } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
import config from '../../../config'
import { AccountMergePlatformIcon } from '../../platforms/icons/icons'
import { backgroundLightGreyColor } from '../../../styles/colors.constants'
import { platformNames, platformLabels } from '../../../enums/platforms.enum'
import AccountMergeConfirmationDetailsContent from './accountMergeConfirmationDetailsContent'

const { platformPresentation } = config
const { platform: CAPlatformName } = platformPresentation.ca

// TODO: responsive style need to be reviewed
// https://cahrh-gitlab.creative-assembly.com/liveservices/platform/access-ui/-/merge_requests/133#note_94776
const AccountMergeConfirmationDetailsCard = styled(Grid)`
    background-color: ${backgroundLightGreyColor};
    margin-top: 10px;
    [${(props): string =>
        props.theme.breakpoints.up('sm').replace('min-width', 'minWidth')}]: {
        width: 536px;
    }
    padding: 16px;
`
const PlatformNameSpan = styled(Box)`
    padding-left: 6px;
    line-height: 28px;
    font-weight: bold;
`

type AccountMergeConfirmationDetailsProps = {
    platform: string
    platformDisplayName: string | undefined
    children: ReactElement
}
const AccountMergeConfirmationDetails = ({
    platform,
    platformDisplayName,
    children,
}: AccountMergeConfirmationDetailsProps): ReactElement => {
    const theme = useTheme()

    const platformName = useMemo(
        (): platformNames =>
            platform ? platformPresentation[platform].platform : CAPlatformName,
        []
    )

    const platformDisplayLabel =
        platformDisplayName || platformLabels[platformName]

    return (
        <AccountMergeConfirmationDetailsCard
            container
            item
            spacing={0}
            direction="column"
            theme={theme}
        >
            <Grid container item direction="row" alignItems="flex-start">
                <Grid item>
                    <AccountMergePlatformIcon
                        size={28}
                        className="platform-icon"
                        platformName={platformName}
                    />
                </Grid>
                <PlatformNameSpan component="span">
                    {config.platformPresentation[platformName].label}
                </PlatformNameSpan>
            </Grid>
            <Grid item>
                <AccountMergeConfirmationDetailsContent
                    platformDisplayLabel={platformDisplayLabel}
                >
                    {children}
                </AccountMergeConfirmationDetailsContent>
            </Grid>
        </AccountMergeConfirmationDetailsCard>
    )
}

export default AccountMergeConfirmationDetails
