import { useCallback, useState } from 'react'
import { i18nNameSpaces } from '../../config/i18n.name-spaces'
import { AlertHookState, CollapsableAlertHook } from './alert.models'
import { alertI18nKeys } from './alert.enums'
import { RejectedHttpResponse } from '../../models/http.models'
import { getAlertI18nKeyFromResponse } from './alert.services'
import { TranslateCb } from '../../models/i18n.models'

const displayAlertState = { shouldDisplayAlert: true, values: {} }
const hideAlertState = { shouldDisplayAlert: false }

export const initialHiddenAlertState = { ...hideAlertState, values: {} }

export const useCollapsableAlert = (
    initialShouldDisplayAlert: AlertHookState
): CollapsableAlertHook => {
    const [
        { shouldDisplayAlert, alertI18nKey, values, responseErrorMessage },
        setAlertState,
    ] = useState<AlertHookState>(initialShouldDisplayAlert)

    const displayAlertWithResponseMessage = useCallback(
        (
            alertI18nKey: alertI18nKeys,
            response: RejectedHttpResponse,
            translateCb: TranslateCb
        ): void => {
            const responseErrorMessage = getAlertI18nKeyFromResponse(response)

            setAlertState({
                ...displayAlertState,
                alertI18nKey,
                responseErrorMessage,
                values: {
                    responseMessage: translateCb(responseErrorMessage, {
                        ns: i18nNameSpaces.alert,
                    }),
                },
            })
        },
        [setAlertState]
    )

    const displayAlertWithI18nKey = useCallback(
        (alertI18nKey: alertI18nKeys): void =>
            setAlertState({ ...displayAlertState, alertI18nKey }),
        []
    )

    const displayAlertWithInterpolation = useCallback(
        (alertI18nKey: alertI18nKeys, values: Record<string, string>): void =>
            setAlertState({ ...displayAlertState, alertI18nKey, values }),
        []
    )

    const collapseAlert = useCallback(
        (): void =>
            setAlertState(
                (state: AlertHookState): AlertHookState => ({
                    ...state,
                    ...hideAlertState,
                })
            ),
        []
    )

    const collapsableAlertProps = {
        shouldDisplayAlert,
        collapseAlert,
        alertI18nKey,
        responseErrorMessage,
        values,
    }

    return {
        collapsableAlertProps,
        displayAlertWithI18nKey,
        displayAlertWithResponseMessage,
        displayAlertWithInterpolation,
    }
}
