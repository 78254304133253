import React, { useMemo } from 'react'
import { Card, CardContent, Container, Grid, Typography } from '@mui/material'
import { Trans } from 'gatsby-plugin-react-i18next'
import { useTheme } from '@mui/material/styles'

import { PlatformDetailsComponent } from '../platform-details/platform-details'
import { getPlatformsDetails } from '../../services/account.service'
import { BrowserOnly } from '../utils/utils'
import { AppLink } from '../link/app.link'
import { supportUrl } from '../../constants/url.constants'

const LinkedAccounts = () => {
    const theme = useTheme()
    const styles = {
        card: {
            marginBottom: 30,
            [theme.breakpoints.up('sm').replace('min-width', 'minWidth')]: {
                width: '536px',
            },
            padding: '16px',
        },
        divider: {
            paddingTop: '20px',
            marginRight: '0px',
        },
        button: {
            textTransform: 'capitalize',
        },
    }
    const platformsDetails = useMemo(getPlatformsDetails, [])

    return (
        <Container component="main" maxWidth="sm">
            <section id="linkedAccounts">
                <Card elevation={3} style={styles.card}>
                    <CardContent>
                        <Trans>
                            <Typography
                                component="h6"
                                variant="h6"
                                align="left"
                            >
                                LINKED ACCOUNTS
                            </Typography>
                        </Trans>
                    </CardContent>
                    <CardContent>
                        <Trans>
                            Select a platform below to link your CA account.
                        </Trans>
                    </CardContent>
                    <CardContent>
                        <Grid container item spacing={2} direction="column">
                            <BrowserOnly>
                                {platformsDetails.map((platformDetails) => (
                                    <PlatformDetailsComponent
                                        key={platformDetails.platformName}
                                        platformDetails={platformDetails}
                                    />
                                ))}
                            </BrowserOnly>
                        </Grid>
                        <Grid sx={{ paddingTop: '10px' }}>
                            <Trans>
                                Need help with linking or deleting an account?
                                Please contact our{' '}
                                <AppLink
                                    to={supportUrl}
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Customer Support
                                </AppLink>
                            </Trans>{' '}
                            team.
                        </Grid>
                    </CardContent>
                </Card>
            </section>
        </Container>
    )
}

export default LinkedAccounts
