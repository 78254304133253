import { useMemo } from 'react'

import { getUser } from '../../../services/auth'

import { badgeStatuses } from './badge.enums'

import { UseEmailBadgeResult } from './badge.models'

export const useEmailBadge = (): UseEmailBadgeResult => {
    const { email_verified: isEmailVerified } = useMemo(getUser, [])
    const emailBadgeStatus = isEmailVerified
        ? badgeStatuses.success
        : badgeStatuses.warning

    return {
        isEmailVerified,
        emailBadgeStatus,
    }
}
