import React, { ReactElement, useState } from 'react'
import { Stack, Box, Drawer, IconButton } from '@mui/material'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GameIcon } from '../../platforms/icons/games'
import { AccountMergePlatformIcon } from '../../platforms/icons/icons'
import CloseIcon from '@mui/icons-material/Close'
import IconCrossProgPublished from '../../../images/cross-progression-published.svg'
import IconCrossProgUnpublished from '../../../images/cross-progression-unpublished.svg'
import {
    AccountMergeReviewGameProfile,
    AccountMergeReviewProps,
} from './accountMergeData.models'
import {
    CaptionMain,
    CaptionSmall,
    Paragraph,
    ReviewGameList,
    ReviewGameListDrawer,
    ReviewGameListDrawerBadge,
    ReviewGameListDrawerHeader,
    ReviewGameListDrawerTitle,
    ReviewGameListParagraph,
    ReviewGameListParagraphIcon,
    ReviewGameListRow,
    ReviewGameListRowContent,
} from './accountMerge.styled'

const AccountMergeReviewGameDetailsRow = ({
    profile,
    rowStatus,
}: {
    profile: AccountMergeReviewGameProfile
    rowStatus: string
}): ReactElement => {
    const { t } = useTranslation()
    return (
        <ReviewGameListRow>
            <ReviewGameListRowContent label={rowStatus}>
                <Box lineHeight={0} alignSelf="stretch">
                    <GameIcon
                        className="gameIcon"
                        game={profile.game}
                        size={64}
                    />
                </Box>
                <Box flexGrow={1} alignSelf="stretch">
                    <CaptionMain>{profile.gameLabel}</CaptionMain>
                    <CaptionSmall color="#666666" sx={{ mt: '8px' }}>
                        {rowStatus === 'unlinked' && (
                            <CaptionSmall>
                                {t('Username: ')}
                                {`${profile.platformUserNickname}`}
                            </CaptionSmall>
                        )}
                        {profile.lastPlayed && (
                            <CaptionSmall>
                                Last saved: {`${profile.lastPlayed}`}
                            </CaptionSmall>
                        )}
                        {profile.created && (
                            <CaptionSmall>
                                Date created: {`${profile.created}`}
                            </CaptionSmall>
                        )}
                        {rowStatus === 'notcross' && (
                            <CaptionSmall>
                                {t('Playable as ')}
                                {`${profile.platformUserNickname}`}
                            </CaptionSmall>
                        )}
                    </CaptionSmall>
                </Box>
                {rowStatus === 'notcross' && (
                    <AccountMergePlatformIcon
                        className="platformIcon"
                        platformName={profile.platform}
                        fullColor={false}
                        fgColor="#666666"
                        bgColor="#FFFFFF"
                        size={24}
                    />
                )}
            </ReviewGameListRowContent>
        </ReviewGameListRow>
    )
}

const AccountMergeReviewGameDetails = ({
    reviewProps,
}: {
    reviewProps: AccountMergeReviewProps
}): ReactElement => {
    const { t } = useTranslation()
    const [showUnlinked, setShowUnlinked] = useState(false)

    return (
        <Stack sx={{ mt: '8px', mb: '24px' }}>
            {reviewProps.gameProfilesSelected.length > 0 && [
                <ReviewGameListParagraph
                    key="cross-caption"
                    sx={{ mt: '18px' }}
                >
                    <ReviewGameListParagraphIcon src={IconCrossProgPublished} />
                    <Paragraph>
                        {t(
                            'Your progress will be shared across your linked platforms for the following games:'
                        )}
                    </Paragraph>
                </ReviewGameListParagraph>,
                <ReviewGameList key="cross-list" sx={{ mt: '18px' }}>
                    {reviewProps.gameProfilesSelected.map(
                        (
                            profile: AccountMergeReviewGameProfile
                        ): ReactElement => (
                            <AccountMergeReviewGameDetailsRow
                                key={`${profile.gameStack}-${profile.platformAccount}`}
                                profile={profile}
                                rowStatus="cross"
                            />
                        )
                    )}
                </ReviewGameList>,
            ]}
            {reviewProps.gameProfilesNonCrossProgression.length > 0 && [
                <ReviewGameListParagraph
                    key="notcross-caption"
                    sx={{ mt: '18px' }}
                >
                    <ReviewGameListParagraphIcon
                        src={IconCrossProgUnpublished}
                    />
                    <Paragraph>
                        {t(
                            'These games do not support cross-progression and will have separate progress per platform:'
                        )}
                    </Paragraph>
                </ReviewGameListParagraph>,
                <ReviewGameList key="notcross-list" sx={{ mt: '18px' }}>
                    {reviewProps.gameProfilesNonCrossProgression.map(
                        (
                            profile: AccountMergeReviewGameProfile
                        ): ReactElement => (
                            <AccountMergeReviewGameDetailsRow
                                key={`${profile.gameStack}-${profile.platformAccount}`}
                                profile={profile}
                                rowStatus="notcross"
                            />
                        )
                    )}
                </ReviewGameList>,
            ]}
            {reviewProps.gameProfilesUnselected.length > 0 && [
                <Paragraph key="unlinked-caption" sx={{ mt: '18px' }}>
                    {t(
                        "Your other profiles won't be deleted, but can no longer be played using this CA account."
                    )}
                </Paragraph>,
                <Paragraph
                    key="unlinked-hide"
                    sx={{ cursor: 'pointer', mt: '18px', color: '#7555A5' }}
                    onClick={(): void => setShowUnlinked(true)}
                >
                    <b>{t('See Other Profiles')}</b>
                </Paragraph>,
                <Drawer
                    key="unlinked-list"
                    anchor="bottom"
                    open={showUnlinked}
                    onClose={(): void => setShowUnlinked(false)}
                >
                    <ReviewGameListDrawer>
                        <ReviewGameListDrawerHeader>
                            <ReviewGameListDrawerTitle sx={{ flexGrow: 1 }}>
                                {t('Inactive game profiles')}
                                <ReviewGameListDrawerBadge
                                    badgeContent={
                                        reviewProps.gameProfilesUnselected
                                            .length
                                    }
                                ></ReviewGameListDrawerBadge>
                            </ReviewGameListDrawerTitle>
                            <IconButton
                                onClick={(): void => setShowUnlinked(false)}
                            >
                                <CloseIcon></CloseIcon>
                            </IconButton>
                        </ReviewGameListDrawerHeader>
                        <ReviewGameList
                            sx={{
                                maxHeight: '60vh',
                                overflow: 'hidden',
                                overflowY: 'auto',
                            }}
                        >
                            {reviewProps.gameProfilesUnselected.map(
                                (
                                    profile: AccountMergeReviewGameProfile
                                ): ReactElement => (
                                    <AccountMergeReviewGameDetailsRow
                                        key={`${profile.gameStack}-${profile.platformAccount}`}
                                        profile={profile}
                                        rowStatus="unlinked"
                                    />
                                )
                            )}
                        </ReviewGameList>
                    </ReviewGameListDrawer>
                </Drawer>,
            ]}
        </Stack>
    )
}

export default AccountMergeReviewGameDetails
