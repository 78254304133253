import React from 'react'
import CardWithLogoXS from '../../shared/card-with-logo-xs'
import { Typography, Box, Button, Card, Grid, CardContent } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { InlineAlert } from '../../alert/alert'
import { alertI18nKeys } from '../../alert/alert.enums'
import CopyButton from '../../copyButton/copyButton'
import {
    darkGrey,
    backgroundLightGreyColor,
} from '../../../styles/colors.constants'
import { supportUrl } from '../../../constants/url.constants'

const AccountMergeError = ({ location }) => {
    const mergeError = JSON.stringify(location?.state?.error, null, 2) || ''
    const theme = useTheme()
    const { t } = useTranslation()

    const styles = {
        button: {
            marginTop: theme.spacing(1),
            textTransform: 'Capitalize',
            border: '2px solid',
            fontWeight: 'bold',
        },
        description: {
            paddingTop: '10px',
        },
        card: {
            backgroundColor: backgroundLightGreyColor,
            [theme.breakpoints.up('sm').replace('min-width', 'minWidth')]: {
                width: '536px',
            },
            marginTop: '10px',
            overflow: 'auto',
        },
        cardText: {
            color: darkGrey,
            fontWeight: '700',
            fontSize: '11px',
        },
        subtitle: {
            paddingTop: '15px',
        },
    }

    return (
        <CardWithLogoXS>
            <Typography component="h1" variant="h5">
                {t('Account Linking Error')}
            </Typography>

            <InlineAlert alertI18nKey={alertI18nKeys.accountsLinkError} />

            <Box style={styles.subtitle} display="flex" justifyContent="start">
                <Typography variant="small">
                    <b>{t('Contact Customer Support')}</b>
                </Typography>
            </Box>
            <Box
                style={styles.description}
                display="flex"
                justifyContent="start"
            >
                <Typography variant="small">
                    {t(
                        'Let us know that you’re having trouble with account linking. Copy the error details below and add to the customer support message.'
                    )}
                </Typography>
            </Box>
            <Card elevation={0} style={styles.card}>
                <CardContent>
                    <Grid
                        container
                        item
                        direction="row"
                        justifyContent="space-between"
                        alignContent="flex-start"
                    >
                        <Grid item style={styles.cardText}>
                            {t('Error Details')}
                        </Grid>
                        <Grid item>
                            <CopyButton copyData={mergeError} />
                        </Grid>
                    </Grid>
                    <Grid>{mergeError}</Grid>
                </CardContent>
            </Card>
            <Button
                type="button"
                style={styles.button}
                color="primary"
                variant="outlined"
                fullWidth
                component="a"
                href={supportUrl}
                rel="noopener"
                target="_blank"
            >
                {t('Contact Support')}
            </Button>
        </CardWithLogoXS>
    )
}

export default AccountMergeError
