exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-complete-tsx": () => import("./../../../src/pages/account/complete.tsx" /* webpackChunkName: "component---src-pages-account-complete-tsx" */),
  "component---src-pages-account-confirm-tsx": () => import("./../../../src/pages/account/confirm.tsx" /* webpackChunkName: "component---src-pages-account-confirm-tsx" */),
  "component---src-pages-account-dateofbirth-tsx": () => import("./../../../src/pages/account/dateofbirth.tsx" /* webpackChunkName: "component---src-pages-account-dateofbirth-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-account-link-default-tsx": () => import("./../../../src/pages/account/link/default.tsx" /* webpackChunkName: "component---src-pages-account-link-default-tsx" */),
  "component---src-pages-account-link-error-tsx": () => import("./../../../src/pages/account/link/error.tsx" /* webpackChunkName: "component---src-pages-account-link-error-tsx" */),
  "component---src-pages-account-link-gameprofiles-tsx": () => import("./../../../src/pages/account/link/gameprofiles.tsx" /* webpackChunkName: "component---src-pages-account-link-gameprofiles-tsx" */),
  "component---src-pages-account-link-index-tsx": () => import("./../../../src/pages/account/link/index.tsx" /* webpackChunkName: "component---src-pages-account-link-index-tsx" */),
  "component---src-pages-account-link-platforms-tsx": () => import("./../../../src/pages/account/link/platforms.tsx" /* webpackChunkName: "component---src-pages-account-link-platforms-tsx" */),
  "component---src-pages-account-link-review-tsx": () => import("./../../../src/pages/account/link/review.tsx" /* webpackChunkName: "component---src-pages-account-link-review-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-email-tsx": () => import("./../../../src/pages/login/email.tsx" /* webpackChunkName: "component---src-pages-login-email-tsx" */),
  "component---src-pages-login-epic-tsx": () => import("./../../../src/pages/login/epic.tsx" /* webpackChunkName: "component---src-pages-login-epic-tsx" */),
  "component---src-pages-login-forgot-tsx": () => import("./../../../src/pages/login/forgot.tsx" /* webpackChunkName: "component---src-pages-login-forgot-tsx" */),
  "component---src-pages-login-game-tsx": () => import("./../../../src/pages/login/game.tsx" /* webpackChunkName: "component---src-pages-login-game-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-psn-tsx": () => import("./../../../src/pages/login/psn.tsx" /* webpackChunkName: "component---src-pages-login-psn-tsx" */),
  "component---src-pages-login-reset-tsx": () => import("./../../../src/pages/login/reset.tsx" /* webpackChunkName: "component---src-pages-login-reset-tsx" */),
  "component---src-pages-login-sent-tsx": () => import("./../../../src/pages/login/sent.tsx" /* webpackChunkName: "component---src-pages-login-sent-tsx" */),
  "component---src-pages-login-steam-tsx": () => import("./../../../src/pages/login/steam.tsx" /* webpackChunkName: "component---src-pages-login-steam-tsx" */),
  "component---src-pages-login-twitch-tsx": () => import("./../../../src/pages/login/twitch.tsx" /* webpackChunkName: "component---src-pages-login-twitch-tsx" */),
  "component---src-pages-login-xbox-tsx": () => import("./../../../src/pages/login/xbox.tsx" /* webpackChunkName: "component---src-pages-login-xbox-tsx" */),
  "component---src-pages-oauth-authorize-tsx": () => import("./../../../src/pages/oauth/authorize.tsx" /* webpackChunkName: "component---src-pages-oauth-authorize-tsx" */),
  "component---src-pages-register-activate-tsx": () => import("./../../../src/pages/register/activate.tsx" /* webpackChunkName: "component---src-pages-register-activate-tsx" */),
  "component---src-pages-register-confirm-tsx": () => import("./../../../src/pages/register/confirm.tsx" /* webpackChunkName: "component---src-pages-register-confirm-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-templates-redirect-tsx": () => import("./../../../src/templates/redirect.tsx" /* webpackChunkName: "component---src-templates-redirect-tsx" */)
}

