import { useState } from 'react'

export type useTogglePasswordVisibilityProps = {
    showPassword: boolean
    togglePasswordVisibility: () => void
}
export const useTogglePasswordVisibility =
    (): useTogglePasswordVisibilityProps => {
        const [showPassword, setShowPassword] = useState<boolean>(false)
        const togglePasswordVisibility = (): void =>
            setShowPassword(!showPassword)

        return { showPassword, togglePasswordVisibility }
    }
