import React, { ReactChild, ReactElement } from 'react'
import { CircularProgress } from '@mui/material'

import { isBrowser } from '../../services/util'
import { BrowserOnlyProps } from './utils.models'

export const BrowserOnly = ({
    children,
    withoutSpinner,
}: BrowserOnlyProps): ReactElement | Array<ReactChild> | ReactChild | null => {
    if (!isBrowser()) {
        return withoutSpinner ? null : <CircularProgress />
    }

    return children
}
