import React from 'react'
import {
    Grid,
    Card,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from '@mui/material'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { Close, MarkEmailUnread } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { backgroundLightGreyColor } from '../../styles/colors.constants'

const UpdateEmailConfirm = (props) => {
    const { open, onClose, newEmail } = props
    const theme = useTheme()
    const { t } = useTranslation()

    const handleClose = () => {
        onClose(false)
    }
    const styles = {
        card: {
            backgroundColor: backgroundLightGreyColor,
            [theme.breakpoints.up('sm').replace('min-width', 'minWidth')]: {
                width: '536px',
                paddingLeft: '24px',
                paddingRight: '24px',
            },
        },
        emailRow: {
            padding: '10px',
        },
        emailLabel: {
            color: 'black',
        },
        dialogActions: {
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingBottom: '24px',
        },
        dialogTitle: {
            textAlign: 'left',
            textTransform: 'capitalize',
        },
        iconButton: {
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
        },
        confirmationText: {
            paddingTop: '20px',
            color: 'black',
        },
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle style={styles.dialogTitle}>
                <Trans>Change Email</Trans>
                {onClose && (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        style={styles.iconButton}
                    >
                        <Close />
                    </IconButton>
                )}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Card elevation={0} style={styles.card}>
                        <Grid
                            container
                            item
                            spacing={1}
                            direction="row"
                            style={styles.emailRow}
                            alignItems="center"
                        >
                            <Grid item xs={1}>
                                <MarkEmailUnread />
                            </Grid>
                            <Grid
                                container
                                xs={11}
                                item
                                direction="column"
                                alignItems="flex-start"
                            >
                                <Grid item>
                                    {t('Check your inbox at')}{' '}
                                    {<b>{newEmail}</b>}{' '}
                                    {t(
                                        'for an email with instructions to confirm your address.'
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                    <Grid item style={styles.confirmationText}>
                        <Trans>
                            If you haven’t received an email, please check your
                            spam folder and make sure you’ve entered your email
                            correctly before contacting our support team.
                        </Trans>
                    </Grid>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default UpdateEmailConfirm
