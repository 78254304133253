import { post } from './api'
import { knownResponseErrors } from '../components/alert/alert.constants'

const baseApiPath = 'auth'
export const handleRegister = ({
    email,
    password,
    nickname,
    dateofbirth,
    privacy_policy,
    accepts_marketing,
    gRecaptchaResponse,
    source,
}) => {
    if (!dateofbirth) {
        return { errors: [knownResponseErrors.dateOfBirthRequiredError] }
    }

    return post(
        `${baseApiPath}/email/register/`,
        {
            nickname,
            email,
            password,
            privacy_policy,
            accepts_marketing,
            dateofbirth,
            'g-recaptcha-response': gRecaptchaResponse,
        },
        {
            'X-CA-Source': source,
        },
        null,
        'v1'
    )
}

export const verifyEmail = ({ token, source }) =>
    post(
        `${baseApiPath}/email/verify/`,
        {},
        {
            'X-CA-Source': source,
            Authorization: `activation ${token}`,
        },
        null,
        'v1'
    )
