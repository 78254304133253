import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import PrivateRoute from '../../../components/route/privateRoute'
import AccountPreMerge from '../../../components/account/link/accountPreMerge'
import AccountMerge from '../../../components/account/link/accountMerge'

import { PageLocationProps } from '../../../models/page.models'
import config from '../../../config'

const component = config.crossProgressionMerge ? AccountPreMerge : AccountMerge

const MergeAccountPage = (pageProps: PageLocationProps): ReactElement => (
    <PrivateRoute
        component={component}
        pageTitle="Link Your Accounts"
        pageProps={pageProps}
    />
)

export default MergeAccountPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
