import React, { ReactElement } from 'react'

import { Grid, Tooltip } from '@mui/material'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import { getLabelI18nValue } from '../../../services/i18n.service'

import { BadgeGridContainer, LabelContainer } from './badge.styled'

import {
    badgeColors,
    badgeIconComponents,
    badgeLabels,
} from './badge.constants'

import { BadgeWithLabelProps, BadgeWithTooltipProps } from './badge.models'

export const BadgeWithLabel = ({
    status,
    type,
}: BadgeWithLabelProps): ReactElement => {
    const { t } = useTranslation()

    const BadgeIcon = badgeIconComponents[status]
    const labelI18nKey = badgeLabels[type][status]

    return (
        <BadgeGridContainer color={badgeColors[status]}>
            <Grid item>
                <BadgeIcon />
            </Grid>
            <LabelContainer item>
                {getLabelI18nValue(t, labelI18nKey)}
            </LabelContainer>
        </BadgeGridContainer>
    )
}

export const BadgeWithTooltip = ({
    status,
    tooltipText,
}: BadgeWithTooltipProps): ReactElement => {
    const BadgeIcon = badgeIconComponents[status]
    return (
        <BadgeGridContainer color={badgeColors[status]}>
            <Grid item>
                <Tooltip title={tooltipText} placement="top-start">
                    <BadgeIcon />
                </Tooltip>
            </Grid>
        </BadgeGridContainer>
    )
}
