import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import { Route } from '../../components/route/route'
import GameLogin from '../../components/login/gameLogin'

const GameLoginPage = (): ReactElement => (
    <Route component={GameLogin} pageTitle="Login" />
)

export default GameLoginPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
