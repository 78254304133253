import React, { ReactElement } from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'
import { darkGrey } from '../../../styles/colors.constants'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

const AccountTextSpan = styled(Box)`
    color: ${darkGrey};
    word-break: break-all;
`
type AccountMergeConfirmationDetailsContentProps = {
    children?: ReactElement
    platformDisplayLabel: string
}
const AccountMergeConfirmationDetailsContent = ({
    platformDisplayLabel,
    children,
}: AccountMergeConfirmationDetailsContentProps): ReactElement =>
    children ? (
        <AccountTextSpan component="span">{children}</AccountTextSpan>
    ) : (
        <AccountTextSpan component="span">
            <Trans>Logged in using </Trans>
            <b>{platformDisplayLabel}</b>
        </AccountTextSpan>
    )

export default AccountMergeConfirmationDetailsContent
