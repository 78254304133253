import React from 'react'

import WarningIcon from '@mui/icons-material/Warning'
import MailOutlineIcon from '@mui/icons-material/MailOutline'

import { alertI18nKeys, alertSeverities } from '../alert/alert.enums'
import { labelI18nKeys } from '../../enums/locales/label.enum'
import { snackBarIcons } from './snackbar.enums'

import { AlertComponentProps } from '../alert/alert.models'

export const snackBarComponentIcons = {
    [snackBarIcons.alert]: <WarningIcon />,
    [snackBarIcons.email]: <MailOutlineIcon />,
}

export const initialSnackBarState = {
    severity: alertSeverities.info,
} as AlertComponentProps

export const successEmailVerifiedSnackBarProps = {
    alertI18nMessageKey: alertI18nKeys.verifyAccountEmailMessage,
    labelI18nTitleKey: labelI18nKeys.verificationSentTitle,
    icon: snackBarIcons.email,
    severity: alertSeverities.info,
}
