import React, { useState } from 'react'
import { Typography, Box } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import CardWithLogoXS from '../shared/card-with-logo-xs'
import { DateOfBirth } from '../shared/date-of-birth/date-of-birth'
import { useTheme } from '@mui/material/styles'
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { updateUserInfo, logoutWithDelay } from '../../services/auth'
import { useSource } from '../../services/util'
import { alertI18nKeys } from '../alert/alert.enums'
import {
    initialHiddenAlertState,
    useCollapsableAlert,
} from '../alert/alert.hook'
import { CollapsableAlert } from '../alert/alert'
import { hasTokenErrors } from '../../services/api'
import { accountUrl } from '../../constants/url.constants'

const CompleteDateOfBirth = () => {
    const theme = useTheme()
    const { navigate } = useI18next()
    const source = useSource()
    const { t } = useTranslation()
    const {
        collapsableAlertProps,
        displayAlertWithI18nKey,
        displayAlertWithResponseMessage,
    } = useCollapsableAlert(initialHiddenAlertState)
    const [loading, setLoading] = useState(false)
    const [{ dateofbirth }, setStateWithDateOfBirth] = useState({
        dateofbirth: null,
    })

    const styles = {
        formButton: {
            marginTop: theme.spacing(2),
        },
        label: {
            fontSize: '0.75rem',
            lineHeight: '0.1em',
            fontFamily: 'Inter',
            fontWeight: '400',
        },
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        collapsableAlertProps.collapseAlert()
        if (!dateofbirth) {
            displayAlertWithI18nKey(alertI18nKeys.dateOfBirthRequiredError)
        } else {
            const response = await updateUserInfo({
                dateofbirth,
                frontend: 'ca',
                source,
            })

            if (!response.errors) {
                navigate(accountUrl, {
                    state: { updatedField: 'date of birth' },
                })
                return
            }
            if (hasTokenErrors(response.errors)) {
                displayAlertWithI18nKey(
                    alertI18nKeys.invalidOrExpiredTokenError
                )
                logoutWithDelay(navigate)
            } else {
                displayAlertWithResponseMessage(
                    alertI18nKeys.unknownRegistrationError,
                    response,
                    t
                )
            }
        }
        setLoading(false)
    }

    return (
        <CardWithLogoXS>
            <Typography component="h1" variant="h5">
                {t('Complete Your Profile')}
            </Typography>
            <Box textAlign="center">
                <Typography variant="small">
                    {t('We need a little more information.')}
                </Typography>
            </Box>

            <CollapsableAlert {...collapsableAlertProps} />

            <Box
                component="form"
                method="post"
                onSubmit={(event) => handleSubmit(event)}
            >
                <DateOfBirth setState={setStateWithDateOfBirth} />
                <LoadingButton
                    style={styles.formButton}
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={loading}
                    fullWidth
                >
                    <Trans>Next</Trans>
                </LoadingButton>
            </Box>
        </CardWithLogoXS>
    )
}

export default CompleteDateOfBirth
