import React, { useState, ReactElement } from 'react'
import CardXS from '../../shared/card-xs'
import { Box, Container, Drawer } from '@mui/material'
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import AccountMergeGameProfilesDetails from './accountMergeGameProfilesDetails'
import StepsProgressBar from '../../shared/StepsProgressBar'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useAccountMergeData } from './accountMergeData.hook'
import { AccountMergeGame } from './accountMergeData.models'
import {
    FormButton,
    Paragraph,
    TitleText,
    GameProfilesTips,
    GameProfilesTipsSwitch,
    GameProfilesList,
} from './accountMerge.styled'
import { accountLinkGameProfilesUrl } from '../../../constants/url.constants'

const AccountMergeGameProfiles = (): ReactElement => {
    const { t } = useTranslation()
    const { navigate } = useI18next()
    const [showTips, setShowTips] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [page, pageProps, profileChoice, saveProfileChoice] =
        useAccountMergeData(accountLinkGameProfilesUrl)

    const handleContinue = async (event: React.MouseEvent): Promise<void> => {
        event.preventDefault()
        setSubmitting(true)
        const unselected = Object.keys(profileChoice).filter(
            (gameStack: string): boolean => !profileChoice[gameStack]
        )
        if (unselected.length === 0) {
            navigate(page.next)
        }
    }

    return (
        <Container component="main" maxWidth="sm">
            <CardXS>
                <StepsProgressBar
                    start={page.stepStart}
                    step={page.stepThis}
                    max={page.stepTotal}
                    title={t(page.stepTitle)}
                />
                <TitleText>{t(page.title)}</TitleText>
                <Paragraph>
                    {t(
                        'There are multiple profiles for the same game on different platforms in your account. After linking, your progress will be shared across your linked platforms.'
                    )}
                    <br />
                </Paragraph>
                <GameProfilesTipsSwitch
                    sx={{ mt: '4px' }}
                    onClick={(): void => setShowTips(true)}
                >
                    {t('What does this mean?')}
                </GameProfilesTipsSwitch>
                <Paragraph sx={{ mt: '18px' }}>
                    {t('Choose your preferred game profile to continue:')}
                </Paragraph>
                {pageProps.gameProfiles && (
                    <Box sx={{ mt: '18px' }}>
                        <GameProfilesList>
                            {pageProps.gameProfiles.map(
                                (gameData: AccountMergeGame): ReactElement => (
                                    <AccountMergeGameProfilesDetails
                                        key={gameData.gameStack}
                                        gameData={gameData}
                                        profileChoice={profileChoice}
                                        saveProfileChoice={saveProfileChoice}
                                        submitting={submitting}
                                    />
                                )
                            )}
                        </GameProfilesList>
                        <FormButton
                            type="submit"
                            color="primary"
                            variant="contained"
                            fullWidth
                            sx={{ mt: '24px' }}
                            onClick={handleContinue}
                        >
                            {t('Continue')}
                        </FormButton>
                        <FormButton
                            type="button"
                            color="primary"
                            variant="text"
                            fullWidth
                            sx={{ mt: '24px' }}
                            onClick={(): Promise<void> => navigate(page.prev)}
                        >
                            {t('Back')}
                        </FormButton>
                    </Box>
                )}
            </CardXS>

            <Drawer
                anchor="bottom"
                open={showTips}
                onClose={(): void => setShowTips(false)}
            >
                <GameProfilesTips>
                    <InfoOutlinedIcon sx={{ color: '#0288D1' }} />
                    <Box>
                        <Paragraph>
                            <Trans>{t('<strong>Don’t worry!</strong>')}</Trans>
                        </Paragraph>
                        <Paragraph>
                            <Trans>
                                {t(
                                    'Your other profiles <strong>won’t</strong> be deleted, but can no longer be played using this CA account.'
                                )}
                            </Trans>
                        </Paragraph>
                    </Box>
                </GameProfilesTips>
            </Drawer>
        </Container>
    )
}

export default AccountMergeGameProfiles
