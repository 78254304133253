import { useI18next } from 'gatsby-plugin-react-i18next'
import { useQuery } from './util'

export const useRedirectOnQueryError = (
    url: string,
    state: Record<string, unknown>
): void => {
    const { navigate } = useI18next()
    const query = useQuery()

    if (query.has('error')) {
        navigate(url, { state })
    }
}
