import React, { ReactElement } from 'react'
import { IconButton, TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { usePassword } from './password.hook'
import { PasswordProps } from './password.models'
import { Visibility, VisibilityOff } from '@mui/icons-material'

export const Password = (passwordProps: PasswordProps): ReactElement => {
    const { fieldProps, btnProps, showPassword } = usePassword(passwordProps)

    return (
        <TextField
            {...fieldProps}
            variant="outlined"
            margin="normal"
            fullWidth
            required
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton {...btnProps} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )
}
