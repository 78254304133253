import React from 'react'
import { Grid, Card, CardContent, Tooltip } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import config from '../../config'

import { AccountDetailsPlatformIcon } from '../platforms/icons/icons'
import { RefreshButton } from '../button/button'
import { BadgeWithLabel } from '../shared/badges/badge'

import { refreshPlatformDetails } from '../../services/storage.service'
import { badgeStatuses, badgeTypes } from '../shared/badges/badge.enums'
import { labelI18nKeys } from '../../enums/locales/label.enum'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {
    darkGrey,
    backgroundLightGreyColor,
} from '../../styles/colors.constants'
import { platformNames } from '../../enums/platforms.enum'
const platformPresentation = config.platformPresentation

const AccountLinked = ({
    platformName,
    isConnectedStatus,
    hasDisplayName,
    displayName,
    lastLogin,
}) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const refreshRequiredTooltipText = t(
        labelI18nKeys.refreshRequiredTooltipText
    )
    const styles = {
        card: {
            backgroundColor: backgroundLightGreyColor,
            [theme.breakpoints.up('sm').replace('min-width', 'minWidth')]: {
                width: '536px',
            },
        },
        platformText: {
            color: darkGrey,
            wordBreak: 'break-all',
        },
        platformDisplayName: {
            wordBreak: 'break-all',
        },
        italicsDisplayName: {
            fontStyle: 'italic',
            wordBreak: 'break-all',
        },
    }

    const shouldShowLastLogin = platformName !== platformNames.twitch

    return (
        <Tooltip
            title={isConnectedStatus ? '' : refreshRequiredTooltipText}
            placement="top-start"
        >
            <Card style={styles.card} elevation={0}>
                <CardContent>
                    <Grid
                        container
                        item
                        spacing={2}
                        direction="row"
                        alignItems="flex-start"
                    >
                        <Grid item xs={2} md={1}>
                            <AccountDetailsPlatformIcon
                                platformName={platformName}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            alignItems="flex-start"
                            xs={10}
                            md={11}
                        >
                            <Grid
                                container
                                item
                                direction="row"
                                alignItems="flex-start"
                            >
                                <Grid item xs={4} sm={2}>
                                    <b>
                                        {
                                            platformPresentation[platformName]
                                                .label
                                        }
                                    </b>
                                </Grid>

                                <BadgeWithLabel
                                    type={badgeTypes.platformStatus}
                                    status={
                                        isConnectedStatus
                                            ? badgeStatuses.success
                                            : badgeStatuses.warning
                                    }
                                />
                            </Grid>
                            <Grid
                                container
                                item
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Grid
                                    item
                                    style={
                                        hasDisplayName
                                            ? styles.platformDisplayName
                                            : styles.italicsDisplayName
                                    }
                                >
                                    <b>{displayName}</b>
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        display: { xs: 'none', md: 'block' },
                                    }}
                                >
                                    <RefreshButton
                                        onClick={() =>
                                            refreshPlatformDetails(platformName)
                                        }
                                    />
                                </Grid>
                            </Grid>
                            {shouldShowLastLogin && (
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    alignItems="center"
                                    style={styles.platformText}
                                >
                                    <Grid>Last Login: {lastLogin}</Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid
                            container
                            item
                            direction="row"
                            alignItems="flex-end"
                            sx={{ display: { xs: 'block', md: 'none' } }}
                        >
                            <RefreshButton
                                onClick={() =>
                                    refreshPlatformDetails(platformName)
                                }
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Tooltip>
    )
}

export default AccountLinked
