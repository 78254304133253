import { styled } from '@mui/material/styles'
import { Stack, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React, { ReactElement } from 'react'

const SpinnerStack = styled(Stack)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
`

const SpinnerSubText = styled(Typography)`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #1a1a1a;
`

export type SpinnerProps = {
    label: string
}
export const Spinner = ({ label }: SpinnerProps): ReactElement => (
    <SpinnerStack>
        <CircularProgress size={128} />
        <SpinnerSubText>{label}</SpinnerSubText>
    </SpinnerStack>
)
