import React, { useEffect, useState } from 'react'
import CardWithLogoXS from '../shared/card-with-logo-xs'
import { Box, Typography } from '@mui/material'
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import {
    getUser,
    platformLogin,
    hasEmail,
    logoutWithDelay,
} from '../../services/auth'
import { isTrustedDomain, useQuery, useSource } from '../../services/util'
import { CollapsableAlert } from '../alert/alert'
import {
    initialHiddenAlertState,
    useCollapsableAlert,
} from '../alert/alert.hook'
import { alertI18nKeys } from '../alert/alert.enums'
import {
    removeRefreshedPlatformData,
    hasRefreshedPlatformData,
    showRefreshedPlatformDataSuccess,
} from '../../services/storage.service'
import { hasTokenErrors } from '../../services/api'
import { accountUrl, accountLinkUrl } from '../../constants/url.constants'

const PlatformLogin = ({ pageTitle, platformName: platform }) => {
    const { t } = useTranslation()
    const { navigate } = useI18next()
    const source = useSource()
    const query = useQuery()
    const {
        collapsableAlertProps,
        displayAlertWithI18nKey,
        displayAlertWithResponseMessage,
        displayAlertWithInterpolation,
    } = useCollapsableAlert(initialHiddenAlertState)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const handleLogin = async () => {
            if (query.has('code')) {
                const code = query.get('code')
                const user = getUser()

                const result = await platformLogin({ platform, code, source })
                setLoading(false)

                if (result.errors) {
                    removeRefreshedPlatformData()
                    if (result.errors.includes('account_exists')) {
                        displayAlertWithInterpolation(
                            alertI18nKeys.accountAlreadyConnectedError,
                            { platform }
                        )
                    } else if (hasTokenErrors(result.errors)) {
                        displayAlertWithI18nKey(
                            alertI18nKeys.invalidOrExpiredTokenError
                        )
                        logoutWithDelay(navigate)
                    } else if (result.errors.includes('not_found')) {
                        displayAlertWithI18nKey(
                            alertI18nKeys.mergedOrDeletedAccountError
                        )
                        logoutWithDelay(navigate)
                    } else {
                        displayAlertWithResponseMessage(
                            alertI18nKeys.platformRequestError,
                            result,
                            t
                        )
                    }
                } else if (result.requires_merge) {
                    navigate(accountLinkUrl, {
                        state: {
                            accessTokenA: user.access_token,
                            accessTokenB: result.user_tokens.access_token,
                            refreshTokenA: user.refresh_token,
                            refreshTokenB: result.user_tokens.refresh_token,
                        },
                    })
                } else {
                    if (hasEmail()) {
                        // @todo next handling is likely to need localstorage, will complete along with OAuth flow
                        const next = query.get('next')
                        if (next && isTrustedDomain(next)) {
                            window.location.href = next
                            return
                        }
                    }
                    if (hasRefreshedPlatformData()) {
                        showRefreshedPlatformDataSuccess()
                    }
                    navigate(accountUrl)
                }
            }
        }
        handleLogin()
    }, [
        query,
        platform,
        source,
        t,
        displayAlertWithI18nKey,
        displayAlertWithResponseMessage,
        displayAlertWithInterpolation,
    ])

    return (
        <CardWithLogoXS>
            <Typography component="h1" variant="h5">
                <Trans>{pageTitle}</Trans>
            </Typography>

            <CollapsableAlert {...collapsableAlertProps} />

            {loading && (
                <Box display="flex" justifyContent="center" mb={3}>
                    <CircularProgress />
                </Box>
            )}
        </CardWithLogoXS>
    )
}

export default PlatformLogin
