import { ChangeEvent } from 'react'

import { resetPasswordRequest } from '../../services/auth'

import { alertI18nKeys } from '../alert/alert.enums'

import { CollapsableAlertHook } from '../alert/alert.models'
import { TranslateCb } from '../../models/i18n.models'

type SubmitForgotPasswordProps = {
    email: string
    setLoading: (isLoading: boolean) => void
    collapsableAlertHook: CollapsableAlertHook
    onSuccess: () => void
    t: TranslateCb
}

const isSuccessResponse = ({ result }: { result: string }): boolean =>
    result === 'accepted'

export const submitForgotPassword = async ({
    email,
    onSuccess,
    setLoading,
    collapsableAlertHook,
    t,
}: SubmitForgotPasswordProps): Promise<void> => {
    const {
        collapsableAlertProps: { collapseAlert },
        displayAlertWithResponseMessage,
    } = collapsableAlertHook

    collapseAlert()
    setLoading(true)

    const response = await resetPasswordRequest(email)
    setLoading(false)

    if (isSuccessResponse(response)) {
        onSuccess()
        return
    }

    displayAlertWithResponseMessage(
        alertI18nKeys.resetPasswordError,
        response,
        t
    )
}

type SubmitForgotPasswordHandler = (
    event: ChangeEvent<HTMLInputElement>
) => void

export const getSubmitForgotPasswordHandler =
    (
        submitForgotPasswordProps: SubmitForgotPasswordProps
    ): SubmitForgotPasswordHandler =>
    (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault()
        submitForgotPassword(submitForgotPasswordProps)
    }
