import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import PublicRoute from '../../components/route/publicRoute'
import Login from '../../components/login/login'

import { PageLocationProps } from '../../models/page.models'

const LoginPage = (pageProps: PageLocationProps): ReactElement => (
    <PublicRoute component={Login} pageTitle="Login" pageProps={pageProps} />
)

export default LoginPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
