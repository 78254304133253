import React, { ReactElement, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Trans, useI18next } from 'gatsby-plugin-react-i18next'
import { isBrowser } from '../services/util'
import Layout from '../components/shared/layout'
import CardWithLogoXS from '../components/shared/card-with-logo-xs'
import { RedirectProps } from './redirect.models'
import { AppLink } from '../components/link/app.link'
import { rootUrl } from '../constants/url.constants'

const Redirect = (props: RedirectProps): ReactElement => {
    const { pageContext } = props
    const { pathName, redirect } = pageContext

    const { navigate } = useI18next()

    useEffect((): void => {
        if (isBrowser()) {
            navigate(
                window.location.href
                    .replace(window.location.origin, '')
                    .replace(pathName, redirect)
            )
        }
    }, [pathName, redirect])

    return (
        <Layout pageTitle={null}>
            <CardWithLogoXS>
                <h1>
                    <Trans>THIS PAGE HAS MOVED</Trans>
                </h1>
                <p>
                    <Trans>
                        The page you were looking for has moved. You should be
                        redirected automatically. If you are not redirected
                        within a few seconds, you may{' '}
                        <AppLink to={redirect} rel="noopener">
                            continue
                        </AppLink>{' '}
                        or go back to the{' '}
                        <AppLink to={rootUrl} rel="noopener">
                            homepage
                        </AppLink>
                        .
                    </Trans>
                </p>
            </CardWithLogoXS>
        </Layout>
    )
}

export default Redirect

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
