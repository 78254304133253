import React, { ReactElement } from 'react'

import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'

import { AlertComponent } from './alert'
import { BoldText } from '../shared/text-field/text-field'

import { alertI18nKeys, alertSeverities } from './alert.enums'

import { EmailAlertWithCloseProps } from './alert.models'
import { labelI18nKeys } from '../../enums/locales/label.enum'

export const EmailUnverifiedNotificationAlert = ({
    email,
    onClose,
    buttonHandler,
}: EmailAlertWithCloseProps): ReactElement => (
    <AlertComponent
        icon={<ForwardToInboxIcon />}
        onClose={onClose}
        severity={alertSeverities.info}
        labelI18nTitleKey={labelI18nKeys.unverifiedEmailNotificationTitle}
        alertI18nMessageKey={alertI18nKeys.unverifiedEmailNotificationMessage}
        components={[<BoldText key={email} />]}
        buttonHandler={buttonHandler}
        labelI18nButtonLabelKey={labelI18nKeys.resendVerificationEmailLabel}
        values={{ email }}
    />
)
