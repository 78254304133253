import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import PublicRoute from '../components/route/publicRoute'
import Home from '../components/home'

import { PageLocationProps } from '../models/page.models'

const App = (pageProps: PageLocationProps): ReactElement => (
    <PublicRoute component={Home} pageTitle="" pageProps={pageProps} />
)

export default App

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
