import React, { ReactElement } from 'react'
import { Stack, Divider } from '@mui/material'
import { AccountMergePlatformIcon } from '../../platforms/icons/icons'
import { AccountMergeReviewPlatformAccount } from './accountMergeData.models'
import { CaptionMain, CaptionSmall, ReviewAccount } from './accountMerge.styled'

const AccountMergeReviewPlatformDetails = ({
    platformAccount,
}: {
    platformAccount: AccountMergeReviewPlatformAccount
}): ReactElement => (
    <ReviewAccount
        divider={
            <Divider
                sx={{ height: '32px', my: 'auto' }}
                orientation="vertical"
            />
        }
    >
        <AccountMergePlatformIcon
            className="platformIcon"
            platformName={platformAccount.platform}
            size={24}
        />
        <Stack flexGrow={1} gap={'2px'}>
            <CaptionMain>{platformAccount.platformUserNickname}</CaptionMain>
            <CaptionSmall color={'#666666'}>
                {platformAccount.platformLabel}
            </CaptionSmall>
        </Stack>
    </ReviewAccount>
)

export default AccountMergeReviewPlatformDetails
