import { Box, Grid } from '@mui/material'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import React, { ReactElement } from 'react'
import { LoginButton } from '../button/button'
import CardWithLogoXS from '../shared/card-with-logo-xs'
import { loginPlatformNames } from '../../constants/platforms.constants'
import { i18nNameSpaces } from '../../config/i18n.name-spaces'
import { labelI18nKeys } from '../../enums/locales/label.enum'
import { AppLink } from '../link/app.link'
import { signUpUrl } from '../../constants/url.constants'

const LoginPage = (): ReactElement => {
    const { t } = useTranslation(i18nNameSpaces.label)
    return (
        <CardWithLogoXS>
            <Box textAlign="center">{t(labelI18nKeys.loginPageTitle)}</Box>

            <Grid
                alignContent="center"
                mt={1}
                container
                item
                spacing={2}
                direction="column"
            >
                {loginPlatformNames.map(
                    (platformName): ReactElement => (
                        <Grid item key={platformName}>
                            <LoginButton
                                platformName={platformName}
                                labelI18nKey={labelI18nKeys.loginButtonLabel}
                                isLoginPage={true}
                            />
                        </Grid>
                    )
                )}
            </Grid>
            <Box mt={3} textAlign="center">
                <Trans>
                    {"Don't have a CA Account? Log in with your platform or "}
                    <AppLink to={signUpUrl}>Sign up</AppLink>
                </Trans>
            </Box>
        </CardWithLogoXS>
    )
}

export default LoginPage
