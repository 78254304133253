import React, { useState } from 'react'
import {
    Typography,
    Container,
    Card,
    CardContent,
    Grid,
    Divider,
    Button,
} from '@mui/material'
import { Trans } from 'gatsby-plugin-react-i18next'
import { getUser } from '../../services/auth'
import {
    PersonOutlineOutlined,
    CalendarTodayOutlined,
    FingerprintOutlined,
} from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import UpdateUsername from './updateUsername'
import UpdateDateOfBirth from './updateDateOfBirth'
import CopyButton from '../copyButton/copyButton'
import { convertToLocaleDateString } from '../../services/dates.service'
import { getNicknameSections } from '../../services/account.service'
import { BadgeWithTooltip } from '../shared/badges/badge'
import { badgeStatuses } from '../shared/badges/badge.enums'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { labelI18nKeys } from '../../enums/locales/label.enum'
import { darkGrey } from '../../styles/colors.constants'

const MyInformation = ({ alertCallback }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const usernameTooltipText = t(labelI18nKeys.usernameTooltipText)
    const styles = {
        card: {
            marginBottom: 30,
            [theme.breakpoints.up('sm').replace('min-width', 'minWidth')]: {
                width: '536px',
            },
            padding: '16px',
        },
        button: {
            textTransform: 'capitalize',
            fontWeight: '500',
            float: 'right',
        },
        nicknameUniqueCode: {
            paddingLeft: '2px',
            color: darkGrey,
        },
    }
    const {
        nickname,
        shortNickname,
        nicknameUniqueCode,
        dateofbirth,
        user_id,
    } = getUser()

    const [openEditUsernameModal, setOpenEditUsernameModal] = useState(false)
    const [openEditDateOfBirthModal, setOpenEditDateOfBirthModal] =
        useState(false)

    const handleEditUsernameOpen = () => {
        setOpenEditUsernameModal(true)
    }
    const handleEditUsernameClose = () => {
        setOpenEditUsernameModal(false)
    }
    const handleEditDateOfBirthOpen = () => {
        setOpenEditDateOfBirthModal(true)
    }
    const handleEditDateOfBirthClose = () => {
        setOpenEditDateOfBirthModal(false)
    }

    return (
        <Container component="main" maxWidth="sm">
            <section id="myInformation">
                <Card elevation={3} style={styles.card}>
                    <CardContent>
                        <Trans>
                            <Typography
                                component="h6"
                                variant="h6"
                                align="left"
                            >
                                MY INFORMATION
                            </Typography>
                        </Trans>
                    </CardContent>
                    <CardContent>
                        <Grid
                            container
                            item
                            spacing={2}
                            direction="row"
                            alignItems="flex-start"
                        >
                            <Grid item xs={2} sm={1}>
                                <FingerprintOutlined />
                            </Grid>
                            <Grid
                                container
                                item
                                direction="column"
                                xs={7.5}
                                sm={9.5}
                            >
                                <Grid item lineHeight="16px">
                                    <b>
                                        <Trans>CA Account ID</Trans>
                                    </b>
                                </Grid>
                                <Grid item lineHeight="28px">
                                    {user_id}
                                </Grid>
                            </Grid>
                            <Grid item xs>
                                <CopyButton copyData={user_id} size="medium" />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider variant="middle" />
                    <CardContent>
                        <Grid
                            container
                            item
                            spacing={2}
                            direction="row"
                            alignItems="flex-start"
                        >
                            <Grid item xs={2} sm={1}>
                                <PersonOutlineOutlined />
                            </Grid>
                            <Grid
                                container
                                item
                                direction="column"
                                xs={8}
                                sm={9}
                            >
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    lineHeight="16px"
                                    alignItems="center"
                                >
                                    <Grid item xs={6.5} sm={3}>
                                        <b>
                                            <Trans>Username</Trans>
                                        </b>
                                    </Grid>
                                    <BadgeWithTooltip
                                        status={badgeStatuses.info}
                                        tooltipText={usernameTooltipText}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    lineHeight="25px"
                                    direction="row"
                                    alignItems="center"
                                >
                                    <Grid item pt="3px">
                                        {shortNickname}
                                    </Grid>
                                    <Grid
                                        item
                                        style={styles.nicknameUniqueCode}
                                        pt="3px"
                                    >
                                        {nicknameUniqueCode}
                                    </Grid>
                                    <Grid item pl={2}>
                                        <CopyButton
                                            copyData={nickname}
                                            size="medium"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Button
                                    type="submit"
                                    style={styles.button}
                                    color="primary"
                                    size="small"
                                    onClick={handleEditUsernameOpen}
                                >
                                    <Trans>Edit</Trans>
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider variant="middle" />
                    <CardContent>
                        <Grid
                            container
                            item
                            spacing={2}
                            direction="row"
                            alignItems="flex-start"
                        >
                            <Grid item xs={2} sm={1}>
                                <CalendarTodayOutlined />
                            </Grid>
                            <Grid
                                container
                                item
                                direction="column"
                                xs={8}
                                sm={9}
                            >
                                <Grid item lineHeight="16px">
                                    <b>
                                        <Trans>Date of Birth</Trans>
                                    </b>
                                </Grid>
                                <Grid item lineHeight="28px">
                                    {dateofbirth
                                        ? convertToLocaleDateString(dateofbirth)
                                        : 'Please set your Date of Birth'}
                                </Grid>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Button
                                    type="submit"
                                    style={styles.button}
                                    color="primary"
                                    size="small"
                                    onClick={handleEditDateOfBirthOpen}
                                >
                                    <Trans>Edit</Trans>
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </section>
            <UpdateUsername
                open={openEditUsernameModal}
                onClose={handleEditUsernameClose}
                currentUsername={shortNickname}
                alertCallback={alertCallback}
            />
            <UpdateDateOfBirth
                open={openEditDateOfBirthModal}
                onClose={handleEditDateOfBirthClose}
                currentDateOfBirth={dateofbirth}
                alertCallback={alertCallback}
            />
        </Container>
    )
}

export default MyInformation
