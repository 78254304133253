import React, { FunctionComponent, ReactElement } from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'

import Layout from '../shared/layout'
import { PlatformLogin } from '../platform'

import { isLoggedIn, hasEmail } from '../../services/auth'

import { accountCompleteUrl } from '../../constants/url.constants'

import {
    ComponentProps,
    PlatformRouteProps,
    RouteProps,
} from '../../models/route.models'

import { isBrowser } from '../../services/util'
import { DEV_ONLY_redirectToInstance } from '../../DEV_ONLY/instance.utils'

export const Route = ({
    component: Component,
    pageTitle,
    platformName,
}: RouteProps): ReactElement | null => {
    const { navigate } = useI18next()
    if (
        isBrowser() &&
        isLoggedIn() &&
        !hasEmail() &&
        !window.location.href.match(/\/account\/(complete|confirm|link)/) &&
        !window.location.href.match(/\/register\/(activate|confirm)/) &&
        !window.location.href.match(/\/login\/reset/)
    ) {
        navigate(accountCompleteUrl)
        return null
    }

    return (
        <Layout pageTitle={pageTitle}>
            <Component pageTitle={pageTitle} platformName={platformName} />
        </Layout>
    )
}

export const PlatformRoute = ({
    platformName,
    pageTitle,
}: PlatformRouteProps): ReactElement => {
    DEV_ONLY_redirectToInstance()

    return (
        <Route
            component={PlatformLogin as FunctionComponent<ComponentProps>}
            pageTitle={pageTitle}
            platformName={platformName}
        />
    )
}
