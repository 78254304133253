import React, { useEffect } from 'react'
import { Container, Grid } from '@mui/material'

import MyInformation from './myInformation'
import Security from './security'
import LinkedAccounts from './linkedAccounts'
import { UnverifiedEmailNotification } from './unverified-email.notification'
import { SnackBarComponent } from '../snackbar/snackbar'

import {
    getRefreshedPlatformData,
    removeRefreshedPlatformData,
} from '../../services/storage.service'

import { useSnackBarComponent } from '../snackbar/snackbar.hook'

import { alertI18nKeys } from '../alert/alert.enums'

const noUpdatedField = ''

const Account = ({ location }) => {
    const { displaySnackBar, hideSnackbar, snackBarState } =
        useSnackBarComponent()

    const displayUpdatedField = (updatedField) => {
        if (!updatedField) {
            return
        }

        window.history.replaceState(
            { ...location?.state, updatedField: noUpdatedField },
            ''
        )
        displaySnackBar({
            alertI18nMessageKey: alertI18nKeys.fieldUpdatedSuccess,
            values: { updatedField },
        })
    }

    useEffect(() => displayUpdatedField(location?.state?.updatedField), [])

    useEffect(() => {
        const { platformLabel, showPlatformRefreshSuccess } =
            getRefreshedPlatformData()

        if (platformLabel) {
            window.history.replaceState(
                { ...location?.state, merged: false },
                ''
            )
            removeRefreshedPlatformData()
            if (showPlatformRefreshSuccess) {
                displaySnackBar({
                    alertI18nMessageKey: alertI18nKeys.platformRefreshedSuccess,
                    values: { platformLabel },
                })
            }
        } else if (location?.state?.merged) {
            window.history.replaceState(
                { ...location.state, merged: false },
                ''
            )
            displaySnackBar({
                alertI18nMessageKey: alertI18nKeys.accountsLinkedSuccess,
            })
        } else if (location?.state?.emailConfirmed) {
            window.history.replaceState(
                { ...location.state, emailConfirmed: false },
                ''
            )
            displaySnackBar({
                alertI18nMessageKey: alertI18nKeys.emailConfirmedSuccess,
            })
        }

        if (location?.state?.linkingCancelled) {
            window.history.replaceState(
                { ...location.state, linkingCancelled: false },
                ''
            )
            displaySnackBar({
                alertI18nMessageKey: alertI18nKeys.accountLinkingCancelled,
            })
        }
    }, [location])

    const styles = {
        container: {
            marginTop: 30,
        },
        sidePanelGrid: {
            position: 'sticky',
            top: '1%',
        },
    }

    return (
        <Container component="main" maxWidth="lg" style={styles.container}>
            <SnackBarComponent
                {...snackBarState}
                hideSnackbar={hideSnackbar}
            ></SnackBarComponent>
            <Grid container spacing={2} direction="row" justifyContent="center">
                <Grid container item xs={12} md={8} alignItems="center">
                    <Grid container item alignItems="center">
                        <UnverifiedEmailNotification
                            displaySnackBar={displaySnackBar}
                        />
                    </Grid>
                    <Grid container item alignItems="center">
                        <MyInformation alertCallback={displayUpdatedField} />
                    </Grid>
                    <Grid container item alignItems="center">
                        <Security
                            alertCallback={displayUpdatedField}
                            displaySnackBar={displaySnackBar}
                        />
                    </Grid>
                    <Grid container item alignItems="center">
                        <LinkedAccounts />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Account
