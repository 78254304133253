import React, { useState } from 'react'
import CardWithLogoXS from '../shared/card-with-logo-xs'
import { Box, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTheme } from '@mui/material/styles'
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { handleGameLogin } from '../../services/auth'
import { useQuery, useSource } from '../../services/util'
import { CollapsableAlert } from '../alert/alert'
import {
    initialHiddenAlertState,
    useCollapsableAlert,
} from '../alert/alert.hook'
import { getAlertI18nKeyFromResponse } from '../alert/alert.services'
import { accountUrl } from '../../constants/url.constants'

const GameLoginPage = () => {
    const theme = useTheme()
    const { t } = useTranslation()
    const { navigate } = useI18next()
    const { collapsableAlertProps, displayAlertWithI18nKey } =
        useCollapsableAlert(initialHiddenAlertState)
    const [loading, setLoading] = useState(false)
    const source = useSource()
    const query = useQuery()

    const styles = {
        avatar: {
            marginBottom: theme.spacing(1),
        },
        formButton: {
            marginTop: theme.spacing(2),
        },
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        collapsableAlertProps.collapseAlert()
        setLoading(true)
        const token = query.get('token')
        const response = await handleGameLogin({ token, source })

        if (response.access_token) {
            navigate(accountUrl)
        } else {
            displayAlertWithI18nKey(getAlertI18nKeyFromResponse(response))
        }
        setLoading(false)
    }

    return (
        <CardWithLogoXS>
            <Typography component="h1" variant="h5">
                {t('Sign into your CA Account')}
            </Typography>

            <CollapsableAlert {...collapsableAlertProps} />

            <Box
                component="form"
                method="post"
                onSubmit={(event) => handleSubmit(event)}
            >
                <Typography>
                    <Trans>
                        Log in to your CA Account to complete your registration.
                        If you are currently logged in with a different account,
                        it will be logged out.
                    </Trans>
                </Typography>

                <LoadingButton
                    style={styles.formButton}
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={loading}
                    fullWidth
                >
                    {t('Log In')}
                </LoadingButton>
            </Box>
        </CardWithLogoXS>
    )
}

export default GameLoginPage
