import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import PrivateRoute from '../../components/route/privateRoute'
import AccountMergeConfirmation from '../../components/account/link/accountMergeConfirmation'

import { PageLocationProps } from '../../models/page.models'

const AccountMergeConfirmationPage = (
    pageProps: PageLocationProps
): ReactElement => (
    <PrivateRoute
        component={AccountMergeConfirmation}
        pageTitle="Complete Your Profile"
        pageProps={pageProps}
    />
)

export default AccountMergeConfirmationPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
