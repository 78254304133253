import React, { useEffect, useState } from 'react'
import CardWithLogoXS from '../shared/card-with-logo-xs'
import { Box, Typography } from '@mui/material'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import { useQuery } from '../../services/util'
import LoginPage from './login'
import { isLoggedInWithRefresh } from '../../services/auth'
import {
    getOauthData,
    oauthAuthorization,
    parseAndStoreOauthData,
    setOauthData,
} from '../../services/oauth'
import { CollapsableAlert } from '../alert/alert'
import {
    initialHiddenAlertState,
    useCollapsableAlert,
} from '../alert/alert.hook'
import { alertI18nKeys } from '../alert/alert.enums'

const OauthPage = () => {
    const query = useQuery()
    const { t } = useTranslation()
    const { collapsableAlertProps, displayAlertWithI18nKey } =
        useCollapsableAlert(initialHiddenAlertState)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const handleRequest = async () => {
            // Refresh so expired tokens will prompt the player to log in.
            if (await isLoggedInWithRefresh()) {
                setLoading(true)
                const oauthData = getOauthData()

                const result = await oauthAuthorization(oauthData)

                // Once we've attempted auth, clear Oauth session data
                setOauthData({})

                // Successful authorization redirects back to the requested URL
                if (result.status_code === 302 && result.headers.Location) {
                    window.location.href =
                        result.headers.Location +
                        (oauthData.state ? `&state=${oauthData.state}` : '')
                } else {
                    displayAlertWithI18nKey(
                        alertI18nKeys.processingRequestError
                    )
                }
            }
        }
        parseAndStoreOauthData(query)
        handleRequest()
    }, [query, t, displayAlertWithI18nKey])

    return (
        <>
            {!loading && <LoginPage />}
            {loading && (
                <CardWithLogoXS>
                    <Typography component="h1" variant="h5">
                        <Trans>Authorizing</Trans>
                    </Typography>

                    <CollapsableAlert {...collapsableAlertProps} />

                    {loading && (
                        <Box display="flex" justifyContent="center" mb={3}>
                            <CircularProgress />
                        </Box>
                    )}
                </CardWithLogoXS>
            )}
        </>
    )
}

export default OauthPage
