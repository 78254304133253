import LoadingButton from '@mui/lab/LoadingButton'
import { Box, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Trans, useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState, useRef } from 'react'
import { handleLogin } from '../../services/auth'
import { useSource } from '../../services/util'
import { CollapsableAlert } from '../alert/alert'
import { alertI18nKeys } from '../alert/alert.enums'
import CardWithLogoXS from '../shared/card-with-logo-xs'
import {
    initialHiddenAlertState,
    useCollapsableAlert,
} from '../alert/alert.hook'
import { getAlertI18nKeyFromResponse } from '../alert/alert.services'
import {
    accountUrl,
    forgotPasswordUrl,
    loginUrl,
    signUpUrl,
} from '../../constants/url.constants'
import { AppLink } from '../link/app.link'

const EmailLogin = ({ location }) => {
    const theme = useTheme()
    const { navigate } = useI18next()
    const { t } = useTranslation()
    const { collapsableAlertProps, displayAlertWithI18nKey } =
        useCollapsableAlert({
            ...initialHiddenAlertState,
            alertI18nKey: location?.state?.alertI18nKey,
            shouldDisplayAlert: Boolean(location?.state?.alertI18nKey),
        })
    const [loading, setLoading] = useState(false)
    const source = useSource()

    const emailRef = useRef('')
    const passwordRef = useRef('')

    const styles = {
        formButton: {
            marginTop: theme.spacing(2),
        },
    }

    useEffect(() => {
        if (location?.state?.emailConfirmed) {
            window.history.replaceState(
                { ...location.state, emailConfirmed: false },
                ''
            )
            displayAlertWithI18nKey(alertI18nKeys.activatedAccountSuccess)
        } else if (location?.state?.passwordReset) {
            window.history.replaceState(
                { ...location.state, passwordReset: false },
                ''
            )
            displayAlertWithI18nKey(alertI18nKeys.resetPasswordSuccess)
        }
    }, [location, displayAlertWithI18nKey])

    const handleSubmit = async (event) => {
        event.preventDefault()
        collapsableAlertProps.collapseAlert()
        setLoading(true)

        const result = await handleLogin({
            source,
            email: emailRef.current.value,
            password: passwordRef.current.value,
        })

        if (result.access_token) {
            navigate(accountUrl)
        } else {
            displayAlertWithI18nKey(getAlertI18nKeyFromResponse(result))
        }
        setLoading(false)
    }

    return (
        <CardWithLogoXS>
            <Typography component="h1" variant="h5">
                {t('Sign into your CA Account')}
            </Typography>

            <CollapsableAlert {...collapsableAlertProps} />

            <Box
                component="form"
                method="post"
                onSubmit={(event) => handleSubmit(event)}
            >
                <TextField
                    label={t('Email Address')}
                    type="email"
                    autoComplete="email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    name="email"
                    inputRef={emailRef}
                />
                <TextField
                    label={t('Password')}
                    type="password"
                    autoComplete="current-password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    name="password"
                    inputRef={passwordRef}
                />
                <Box mb={1} textAlign="right">
                    <AppLink to={forgotPasswordUrl}>
                        <Trans>Forgot Password?</Trans>
                    </AppLink>
                </Box>

                <LoadingButton
                    style={styles.formButton}
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={loading}
                    fullWidth
                >
                    {t('Log In')}
                </LoadingButton>

                <Box mt={3} textAlign="center">
                    <Trans>
                        Don't have an account?{' '}
                        <AppLink to={signUpUrl}>Sign up</AppLink>
                    </Trans>
                </Box>
                <Box mt={2} textAlign="center">
                    <Trans>
                        Back to all{' '}
                        <AppLink to={loginUrl}>sign in options</AppLink>.
                    </Trans>
                </Box>
            </Box>
        </CardWithLogoXS>
    )
}

export default EmailLogin
