import React from 'react'
import { isLoggedIn, hasEmail, hasDateOfBirth } from '../../services/auth'
import { isBrowser } from '../../services/util'
import { getOauthData } from '../../services/oauth'
import Layout from '../shared/layout'
import { useI18next } from 'gatsby-plugin-react-i18next'
import {
    accountCompleteUrl,
    accountCompleteDateOfBirthUrl,
    loginUrl,
    oauthAuthorizationUrl,
} from '../../constants/url.constants'

const PrivateRoute = ({ component: Component, pageTitle, pageProps }) => {
    const { navigate } = useI18next()
    if (isBrowser()) {
        if (!isLoggedIn()) {
            navigate(loginUrl)
            return null
        } else if (!hasEmail()) {
            if (
                !window.location.href.match(
                    /\/account\/(complete|confirm|link)/
                )
            ) {
                navigate(accountCompleteUrl)
                return null
            }
        } else if (!hasDateOfBirth()) {
            if (
                !window.location.href.match(
                    /\/account\/(complete|confirm|link|dateofbirth)/
                )
            ) {
                navigate(accountCompleteDateOfBirthUrl)
                return null
            }
        } else {
            const oauthParams = getOauthData()

            if ('client_id' in oauthParams) {
                navigate(oauthAuthorizationUrl)
                return null
            }
        }
    }

    return (
        <Layout pageTitle={pageTitle}>
            <Component {...pageProps} />
        </Layout>
    )
}

export default PrivateRoute
