import React, { useState } from 'react'
import CardWithLogoXS from '../shared/card-with-logo-xs'
import { Box, TextField, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { CollapsableAlert } from '../alert/alert'
import {
    initialHiddenAlertState,
    useCollapsableAlert,
} from '../alert/alert.hook'
import { resetPasswordUrl } from '../../constants/url.constants'
import { getSubmitForgotPasswordHandler } from './resetPasswordSubmit.service'

const ResetPasswordRequest = () => {
    const { t } = useTranslation()
    const { navigate } = useI18next()
    const [email, setEmail] = useState('')
    const collapsableAlertHook = useCollapsableAlert(initialHiddenAlertState)
    const [loading, setLoading] = React.useState(false)

    const resetPasswordHandler = getSubmitForgotPasswordHandler({
        email,
        collapsableAlertHook,
        setLoading,
        t,
        onSuccess: () => navigate(resetPasswordUrl),
    })

    return (
        <CardWithLogoXS>
            <Typography component="h1" variant="h5">
                {t('Trouble Logging In?')}
            </Typography>
            <Box textAlign="center">
                <Typography variant="small">
                    {t(
                        'Please enter the e-mail you used to create your CA account.'
                    )}
                </Typography>
            </Box>

            <CollapsableAlert {...collapsableAlertHook.collapsableAlertProps} />

            <Box component="form" method="post" onSubmit={resetPasswordHandler}>
                <TextField
                    label={t('Email Address')}
                    type="email"
                    autoComplete="email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    name="email"
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value)
                    }}
                />
                <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={loading}
                    fullWidth
                >
                    {t('Reset Password')}
                </LoadingButton>
            </Box>
        </CardWithLogoXS>
    )
}

export default ResetPasswordRequest
