import React, { ReactElement } from 'react'

import { graphql } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'

import Layout from '../components/shared/layout'
import CardWithLogoXS from '../components/shared/card-with-logo-xs'
import { AppLink } from '../components/link/app.link'

import { loginUrl } from '../constants/url.constants'

const NotFoundPage = (): ReactElement => (
    <Layout pageTitle="">
        <CardWithLogoXS>
            <h1>
                <Trans>NOT FOUND</Trans>
            </h1>
            <p>
                <Trans>
                    {`The page you were looking for doesn't exist or has moved. Go back to the `}
                    <AppLink to={loginUrl} rel="noopener">
                        homepage.
                    </AppLink>
                </Trans>
            </p>
        </CardWithLogoXS>
    </Layout>
)

export default NotFoundPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
