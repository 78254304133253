import React, { useEffect, useState } from 'react'
import CardWithLogoXS from '../../shared/card-with-logo-xs'
import { Box, Button, Divider, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { styled, useTheme } from '@mui/material/styles'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { getUser, handleLogin } from '../../../services/auth'
import AccountMergeConfirmationDetails from './accountMergeConfirmationDetails'
import { isBrowser, useSource } from '../../../services/util'
import { CollapsableAlert } from '../../alert/alert'
import {
    initialHiddenAlertState,
    useCollapsableAlert,
} from '../../alert/alert.hook'
import { alertI18nKeys } from '../../alert/alert.enums'
import { getAlertI18nKeyFromResponse } from '../../alert/alert.services'
import { Password } from '../../shared/password/password'
import {
    accountCompleteUrl,
    accountLinkUrl,
} from '../../../constants/url.constants'
import { getSubmitForgotPasswordHandler } from '../../login/resetPasswordSubmit.service'

const formStyledButton = (component) => styled(component)`
    margin-top: ${(props) => props.theme.spacing(2)};
    text-transform: Capitalize;
`
const StyledButton = formStyledButton(Button)
const StyledLoadingButton = formStyledButton(LoadingButton)
const SubtitleBox = styled(Box)`
    padding-top: 16px;
`
const StyledDivider = styled(Divider)`
    margin-top: ${(props) => props.theme.spacing(2)};
    margin-bottom: ${(props) => props.theme.spacing(0)};
`
const ForgotPasswordButton = styled(LoadingButton)`
    text-transform: none;
    text-decoration: none;
    line-height: 1;
    :hover {
        text-transform: none;
        text-decoration: underline;
        background-color: inherit;
    }
`

const AccountMergeConfirmation = ({ location }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const { navigate } = useI18next()
    const collapsableAlertHook = useCollapsableAlert(initialHiddenAlertState)
    const [loading, setLoading] = useState(false)
    const source = useSource()
    const [state, setState] = useState({
        email: location?.state?.email,
        password: '',
        source: source,
    })

    const { platform, platforms } = getUser()

    const { collapsableAlertProps, displayAlertWithI18nKey } =
        collapsableAlertHook

    const platformDisplayName = platforms?.[platform]?.display_name

    const resetPasswordHandler = getSubmitForgotPasswordHandler({
        email: state.email,
        collapsableAlertHook,
        setLoading,
        t,
        onSuccess: () =>
            displayAlertWithI18nKey(alertI18nKeys.resetPasswordSent),
    })

    const handleSubmit = async (event) => {
        event.preventDefault()
        collapsableAlertProps.collapseAlert()
        setLoading(true)
        const response = await handleLogin({ ...state, skipRefresh: true })

        if (response.access_token) {
            navigate(accountLinkUrl, {
                state: {
                    accessTokenA: response.access_token,
                    accessTokenB: location?.state?.mergeAccessToken,
                    refreshTokenA: response.refresh_token,
                    refreshTokenB: location?.state?.mergeRefreshToken,
                },
            })
        } else {
            const {
                unauthorizedAccountError,
                accountMergeUnauthorizedAccountError,
            } = alertI18nKeys

            let alertI18nKey = getAlertI18nKeyFromResponse(response)
            alertI18nKey =
                alertI18nKey === unauthorizedAccountError
                    ? accountMergeUnauthorizedAccountError
                    : alertI18nKey
            displayAlertWithI18nKey(alertI18nKey)
        }
        setLoading(false)
    }
    if (isBrowser()) {
        if (!location?.state?.email) {
            navigate(accountCompleteUrl)
            return
        }
    }

    return (
        <CardWithLogoXS>
            <Typography component="h1" variant="h5">
                {t('Complete Your Profile')}
            </Typography>
            <Box textAlign="center">
                <Typography variant="small">
                    {t('We need a little more information.')}
                </Typography>
            </Box>
            <AccountMergeConfirmationDetails
                platform={platform}
                platformDisplayName={platformDisplayName}
            />
            <AccountMergeConfirmationDetails>
                <Box component="form" method="post" onSubmit={handleSubmit}>
                    <Typography variant="small">
                        {t('The email address ')}
                        <b>{state.email}</b>{' '}
                        {t(
                            ' is already in use by another account. If this is your account, please enter your password below.'
                        )}
                    </Typography>
                    <Password
                        label={t('Password')}
                        name="password"
                        autoComplete="current-password"
                        setState={setState}
                    />
                    <Box mb={1} textAlign="right">
                        <ForgotPasswordButton
                            onClick={resetPasswordHandler}
                            loading={loading}
                        >
                            {t('Forgot Password?')}
                        </ForgotPasswordButton>
                    </Box>
                    <CollapsableAlert {...collapsableAlertProps} />
                    <StyledLoadingButton
                        theme={theme}
                        type="submit"
                        color="primary"
                        variant="contained"
                        loading={loading}
                        fullWidth
                    >
                        {t('Authenticate')}
                    </StyledLoadingButton>
                </Box>
            </AccountMergeConfirmationDetails>
            <StyledDivider theme={theme}>or</StyledDivider>
            <SubtitleBox display="flex" justifyContent="center">
                <Typography variant="small">
                    {t('Want to use another email to create a new profile?')}
                </Typography>
            </SubtitleBox>
            <StyledButton
                type="button"
                theme={theme}
                color="primary"
                variant="outlined"
                fullWidth
                onClick={() => navigate(accountCompleteUrl)}
            >
                {t('Enter Another Email Address')}
            </StyledButton>
        </CardWithLogoXS>
    )
}

export default AccountMergeConfirmation
