import React, { ReactElement } from 'react'

import { AlertLink } from '../link/link'
import { BoldText } from '../shared/text-field/text-field'

import { alertI18nKeySeverities } from '../alert/alert.constants'
import { alertI18nKeysToLinkKeys } from '../link/link.constants'

import { alertI18nKeys, alertSeverities } from '../alert/alert.enums'

export const getSeverity = (
    severity: alertSeverities | undefined,
    alertI18nKey: alertI18nKeys
): alertSeverities =>
    severity ||
    alertI18nKeySeverities.get(alertI18nKey) ||
    alertSeverities.error

export const getComponents = (alertI18nKey: alertI18nKeys): [ReactElement] =>
    alertI18nKeysToLinkKeys.has(alertI18nKey)
        ? [<AlertLink key={alertI18nKey} responseErrorMessage={alertI18nKey} />]
        : [<BoldText key={alertI18nKey} />]
