import React from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'

import Layout from '../shared/layout'

import { isLoggedIn } from '../../services/auth'
import { isBrowser } from '../../services/util'
import { accountUrl } from '../../constants/url.constants'

const PublicRoute = ({ component: Component, pageTitle, pageProps }) => {
    const { navigate } = useI18next()

    if (isBrowser && isLoggedIn()) {
        navigate(accountUrl)
        return null
    }

    return (
        <Layout pageTitle={pageTitle}>
            <Component {...pageProps} />
        </Layout>
    )
}

export default PublicRoute
