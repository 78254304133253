import React, { ReactElement } from 'react'
import { LoginButton } from '../button/button'
import { labelI18nKeys } from '../../enums/locales/label.enum'
import { UnconnectedPlatformDetails } from '../../models/platform.models'

const AccountUnlinked = ({
    platformName,
}: UnconnectedPlatformDetails): ReactElement => (
    <LoginButton
        platformName={platformName}
        labelI18nKey={labelI18nKeys.accountUnlinkedButtonLabel}
        isLoginPage={false}
    />
)

export default AccountUnlinked
