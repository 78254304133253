import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'

import React, { ReactElement } from 'react'
import {
    PlatformIconContainer,
    RefreshStyledButton,
    StyledLoginButton,
} from './button.styled'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
    AlertIconButtonProps,
    LoginButtonProps,
    RefreshButtonProps,
} from './button.models'
import { platformNames } from '../../enums/platforms.enum'
import { Trans, useI18next } from 'gatsby-plugin-react-i18next'
import { i18nNameSpaces } from '../../config/i18n.name-spaces'
import { startPlatformLogin } from '../../services/auth'
import { PlatformIcon } from '../platforms/icons/icons'
import config from '../../config'
import { buttonColors } from './button.emum'
import { emailLoginUrl } from '../../constants/url.constants'
const platformPresentation = config.platformPresentation

export const AlertIconButton = ({
    onClick,
}: AlertIconButtonProps): ReactElement => (
    <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={onClick}
    >
        <CloseIcon fontSize="inherit" />
    </IconButton>
)

export const RefreshButton = ({
    onClick,
    color = buttonColors.primary,
}: RefreshButtonProps): ReactElement => (
    <RefreshStyledButton
        startIcon={<RefreshIcon />}
        onClick={onClick}
        color={color}
    >
        Refresh
    </RefreshStyledButton>
)

export const LoginButton = ({
    platformName,
    labelI18nKey,
    isLoginPage,
}: LoginButtonProps): ReactElement => {
    const { navigate } = useI18next()
    const platformLabel = platformPresentation[platformName].label

    const handleButtonClick = (platformName: platformNames): void => {
        if (platformName === platformNames.ca) {
            navigate(emailLoginUrl)
        } else {
            startPlatformLogin(platformName)
        }
    }

    return (
        <StyledLoginButton
            variant="outlined"
            size="large"
            startIcon={
                <PlatformIconContainer
                    className="platform-icon-container"
                    platformName={platformName}
                >
                    <PlatformIcon
                        className="platform-icon"
                        platformName={platformName}
                    />
                </PlatformIconContainer>
            }
            onClick={(): void => handleButtonClick(platformName)}
            isLoginPage={isLoginPage}
        >
            <b>
                <Trans
                    ns={i18nNameSpaces.label}
                    i18nKey={labelI18nKey}
                    values={{ platformLabel }}
                />
            </b>
        </StyledLoginButton>
    )
}
