import React, { useState } from 'react'
import {
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Grid,
    Typography,
} from '@mui/material'
import { Trans, useI18next } from 'gatsby-plugin-react-i18next'
import { getUser } from '../../services/auth'
import { VpnKeyOutlined, MailOutlined } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import UpdateEmail from './updateEmail'
import UpdatePassword from './updatePassword'
import { BadgeWithLabel } from '../shared/badges/badge'
import { SecurityNotificationContainer } from '../shared/styled.layout'
import { EmailUnverifiedAlert } from '../alert/email-unverified.alert'
import { useEmailBadge } from '../shared/badges/badge.hook'
import { badgeTypes } from '../shared/badges/badge.enums'
import { BrowserOnly } from '../utils/utils'
import { verificationEmailHandler } from '../../services/verification.service'

const Security = ({ alertCallback, displaySnackBar }) => {
    const { navigate } = useI18next()
    const theme = useTheme()
    const styles = {
        card: {
            marginBottom: 30,
            [theme.breakpoints.up('sm').replace('min-width', 'minWidth')]: {
                width: '536px',
            },
            padding: '16px',
        },
        button: {
            textTransform: 'capitalize',
            fontWeight: '500',
            float: 'right',
        },
        email: {
            wordBreak: 'break-all',
        },
    }
    const { email } = getUser()

    const [openEditEmailModal, setOpenEditEmailModal] = useState(false)
    const [openEditPasswordModal, setOpenEditPasswordModal] = useState(false)

    const { isEmailVerified, emailBadgeStatus } = useEmailBadge()

    const handleEditEmailOpen = () => {
        setOpenEditEmailModal(true)
    }
    const handleEditEmailClose = () => {
        setOpenEditEmailModal(false)
    }
    const handleEditPasswordOpen = () => {
        setOpenEditPasswordModal(true)
    }
    const handleEditPasswordClose = () => {
        setOpenEditPasswordModal(false)
    }
    const buttonHandler = () =>
        verificationEmailHandler(displaySnackBar, email, navigate)

    return (
        <Container component="main" maxWidth="sm">
            <section id="security">
                <Card elevation={3} style={styles.card}>
                    <CardContent>
                        <Trans>
                            <Typography
                                component="h6"
                                variant="h6"
                                align="left"
                            >
                                SECURITY
                            </Typography>
                        </Trans>
                    </CardContent>
                    <BrowserOnly>
                        <CardContent>
                            <Grid
                                container
                                item
                                spacing={2}
                                direction="row"
                                alignItems="flex-start"
                            >
                                <Grid item xs={2} sm={1}>
                                    <MailOutlined />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    direction="column"
                                    xs={8}
                                    sm={9}
                                >
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        lineHeight="16px"
                                        alignItems="center"
                                        xs={12}
                                    >
                                        <Grid item xs={4} sm={2}>
                                            <b>
                                                <Trans>Email</Trans>
                                            </b>
                                        </Grid>
                                        <BadgeWithLabel
                                            status={emailBadgeStatus}
                                            type={
                                                badgeTypes.emailActivationStatus
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lineHeight="28px"
                                        style={styles.email}
                                    >
                                        {email}
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <Button
                                        type="submit"
                                        style={styles.button}
                                        color="primary"
                                        size="small"
                                        onClick={handleEditEmailOpen}
                                    >
                                        <Trans>Edit</Trans>
                                    </Button>
                                </Grid>
                            </Grid>
                            {isEmailVerified ? null : (
                                <SecurityNotificationContainer
                                    container
                                    direction="row"
                                    alignItems="flex-start"
                                >
                                    <Grid item xs={2} sm={1} />
                                    <Grid item xs={10} sm={11}>
                                        <EmailUnverifiedAlert
                                            email={email}
                                            buttonHandler={buttonHandler}
                                        />
                                    </Grid>
                                </SecurityNotificationContainer>
                            )}
                        </CardContent>
                        <Divider variant="middle" />
                        <CardContent>
                            <Grid
                                container
                                item
                                spacing={2}
                                direction="row"
                                alignItems="flex-start"
                            >
                                <Grid item xs={2} sm={1}>
                                    <VpnKeyOutlined />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    direction="column"
                                    xs={8}
                                    sm={9}
                                >
                                    <Grid item lineHeight="16px">
                                        <b>
                                            <Trans>Password</Trans>
                                        </b>
                                    </Grid>
                                    <Grid item lineHeight="28px">
                                        **********
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <Button
                                        type="submit"
                                        style={styles.button}
                                        color="primary"
                                        size="small"
                                        onClick={handleEditPasswordOpen}
                                    >
                                        <Trans>Edit</Trans>
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </BrowserOnly>
                </Card>
            </section>
            <UpdateEmail
                open={openEditEmailModal}
                onClose={handleEditEmailClose}
                currentEmail={email}
            />
            <UpdatePassword
                open={openEditPasswordModal}
                onClose={handleEditPasswordClose}
                alertCallback={alertCallback}
            />
        </Container>
    )
}

export default Security
