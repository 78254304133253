import { resendVerificationEmail, logoutWithDelay } from './auth'
import { getAlertI18nKeyFromResponse } from '../components/alert/alert.services'
import { getSeverity } from '../components/snackbar/snackbar.service'

import { successEmailVerifiedSnackBarProps } from '../components/snackbar/snackbar.constants'

import { snackBarIcons } from '../components/snackbar/snackbar.enums'

import { RejectedHttpResponse } from '../models/http.models'
import {
    DisplaySnackBar,
    DisplaySnackBarProps,
} from '../components/snackbar/snackbar.models'

import { I18nextNavigateFn } from '../types'

const getVerificationEmailSnackbarProps = (
    response: RejectedHttpResponse,
    email: string
): DisplaySnackBarProps => {
    if (response.errors) {
        const alertI18nMessageKey = getAlertI18nKeyFromResponse(response)
        const severity = getSeverity(undefined, alertI18nMessageKey)

        return {
            severity,
            alertI18nMessageKey,
            icon: snackBarIcons.alert,
        }
    }

    return {
        ...successEmailVerifiedSnackBarProps,
        values: { email },
    }
}

export const verificationEmailHandler = async (
    displaySnackBar: DisplaySnackBar,
    email: string,
    navigate: I18nextNavigateFn
): Promise<void> => {
    const response = await resendVerificationEmail()

    displaySnackBar(getVerificationEmailSnackbarProps(response, email))

    if (response.errors) {
        logoutWithDelay(navigate)
    }
}
