import React from 'react'
import CardWithLogoXS from '../shared/card-with-logo-xs'
import { Typography, Box, Grid } from '@mui/material'
import { Trans } from 'gatsby-plugin-react-i18next'
import { AppLink } from '../link/app.link'
import { supportUrl } from '../../constants/url.constants'

const RegisterConfirm = () => {
    const styles = {
        boldText: {
            fontWeight: 'bold',
            color: '#442574',
        },
    }

    return (
        <CardWithLogoXS>
            <Typography component="h1" variant="h5">
                <Trans>Verify your Email</Trans>
            </Typography>

            <Box display="flex" justifyContent="center" mb={3}>
                <Typography variant="small">
                    <Grid>
                        <Trans>
                            We've sent you an email. Click on the verification
                            link to activate your CA Account.
                        </Trans>
                    </Grid>
                    <Grid
                        textAlign="center"
                        style={styles.boldText}
                        color="primary"
                    >
                        <Trans>Not seeing it?</Trans>
                    </Grid>
                    <Grid>
                        <Trans>
                            It may take a few minutes to hit your inbox. It may
                            also be in your spam folder. Be sure to add us to
                            your contact list to avoid missing future
                            communications. Need help? Please contact our{' '}
                            <AppLink
                                to={supportUrl}
                                rel="noopener"
                                target="_blank"
                            >
                                Customer Support
                            </AppLink>{' '}
                            team.
                        </Trans>
                    </Grid>
                </Typography>
            </Box>
        </CardWithLogoXS>
    )
}

export default RegisterConfirm
