import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import PublicRoute from '../../components/route/publicRoute'
import ResetPasswordRequest from '../../components/login/resetPasswordRequest'

import { PageLocationProps } from '../../models/page.models'

const ForgotPasswordPage = (pageProps: PageLocationProps): ReactElement => (
    <PublicRoute
        component={ResetPasswordRequest}
        pageTitle="Reset Your Password"
        pageProps={pageProps}
    />
)

export default ForgotPasswordPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
