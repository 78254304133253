import React, { ReactElement } from 'react'
import { Trans } from 'react-i18next'
import { Stack, Box, Divider } from '@mui/material'
import { AccountMergePlatformIcon } from '../../platforms/icons/icons'
import { GameIcon } from '../../platforms/icons/games'
import {
    AccountMergePlatformAccount,
    AccountMergePlatformGame,
} from './accountMergeData.models'
import {
    Paragraph,
    PlatformCard,
    PlatformCardGameProfileList,
    PlatformCardGameProfileRow,
    CaptionSmall,
    PlatformCardTitle,
    PlatformCardTitleOuter,
    CaptionMain,
} from './accountMerge.styled'

const AccountMergePlatformsDetailsGameRow = ({
    gameData,
}: {
    gameData: AccountMergePlatformGame
}): ReactElement => (
    <PlatformCardGameProfileRow direction="row">
        <GameIcon className="gameIcon" game={gameData.game} size={48} />
        <Box sx={{ flexGrow: 1 }}>
            <CaptionSmall>
                <b>{gameData.gameLabel}</b>
            </CaptionSmall>
            {gameData.lastPlayed && (
                <CaptionSmall>Last saved: {gameData.lastPlayed}</CaptionSmall>
            )}
            {gameData.created && (
                <CaptionSmall>Date created: {gameData.created}</CaptionSmall>
            )}
        </Box>
    </PlatformCardGameProfileRow>
)

const AccountMergePlatformsDetails = ({
    platformAccount,
}: {
    platformAccount: AccountMergePlatformAccount
}): ReactElement => (
    <Box sx={{ mt: '18px' }}>
        <Paragraph>
            <Trans>
                We found some game profiles associated with your{' '}
                {platformAccount.platformLabel} account:
            </Trans>
        </Paragraph>
        <PlatformCard sx={{ mt: '18px' }}>
            <PlatformCardTitleOuter>
                <PlatformCardTitle>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <AccountMergePlatformIcon
                            className="platformIcon"
                            platformName={platformAccount.platform}
                            size={24}
                        />
                        <Divider
                            sx={{ height: '32px', my: 'auto' }}
                            orientation="vertical"
                            flexItem
                        />
                        <CaptionMain sx={{ flexGrow: 1 }}>
                            {platformAccount.platformUserNickname}
                        </CaptionMain>
                    </Stack>
                </PlatformCardTitle>
            </PlatformCardTitleOuter>
            <PlatformCardGameProfileList
                divider={<Divider orientation="horizontal" flexItem />}
            >
                {platformAccount.platformGames.map(
                    (gameData: AccountMergePlatformGame): ReactElement => (
                        <AccountMergePlatformsDetailsGameRow
                            key={gameData.game}
                            gameData={gameData}
                        />
                    )
                )}
            </PlatformCardGameProfileList>
        </PlatformCard>
    </Box>
)

export default AccountMergePlatformsDetails
