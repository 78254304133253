import React, { ReactElement } from 'react'
import CardXS from '../../shared/card-xs'
import { Box, Container } from '@mui/material'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import AccountMergePlatformsDetails from './accountMergePlatformsDetails'
import StepsProgressBar from '../../shared/StepsProgressBar'
import { useAccountMergeData } from './accountMergeData.hook'
import { AccountMergePlatformAccount } from './accountMergeData.models'
import { FormButton, TitleText } from './accountMerge.styled'
import { accountLinkPlatformsUrl } from '../../../constants/url.constants'

const AccountMergePlatforms = (): ReactElement => {
    const { t } = useTranslation()
    const { navigate } = useI18next()
    const [page, pageProps] = useAccountMergeData(accountLinkPlatformsUrl)

    const handleContinue = async (event: React.MouseEvent): Promise<void> => {
        event.preventDefault()
        navigate(page.next)
    }

    return (
        <Container component="main" maxWidth="sm">
            <CardXS>
                <StepsProgressBar
                    start={page.stepStart}
                    step={page.stepThis}
                    max={page.stepTotal}
                    title={t(page.stepTitle)}
                />
                <TitleText>{t(page.title)}</TitleText>
                {pageProps.platform && (
                    <Box>
                        {pageProps.platform.map(
                            (
                                platformAccount: AccountMergePlatformAccount
                            ): ReactElement => (
                                <AccountMergePlatformsDetails
                                    key={platformAccount.platformUserId}
                                    platformAccount={platformAccount}
                                />
                            )
                        )}
                        <FormButton
                            type="submit"
                            color="primary"
                            variant="contained"
                            fullWidth
                            sx={{ mt: '24px' }}
                            onClick={handleContinue}
                        >
                            {t('Continue')}
                        </FormButton>
                        <FormButton
                            type="button"
                            color="primary"
                            variant="text"
                            fullWidth
                            sx={{ mt: '24px' }}
                            onClick={(): Promise<void> => navigate(page.prev)}
                        >
                            {t('Back')}
                        </FormButton>
                    </Box>
                )}
            </CardXS>
        </Container>
    )
}

export default AccountMergePlatforms
