import React from 'react'
import CardWithLogoXS from '../shared/card-with-logo-xs'
import { Typography, Box } from '@mui/material'
import { Trans } from 'gatsby-plugin-react-i18next'

const ResetPasswordSent = () => {
    return (
        <CardWithLogoXS>
            <Typography component="h1" variant="h5">
                <Trans>Reset Password Request Sent</Trans>
            </Typography>

            <Box display="flex" justifyContent="center" mb={3}>
                <Typography variant="small">
                    <Trans>
                        Please check your inbox for an email with instructions
                        to change your password.{' '}
                    </Trans>

                    <Trans>
                        If you haven’t received an email, please check your spam
                        folder and make sure you’ve entered your email correctly
                        before contacting our support team.
                    </Trans>
                </Typography>
            </Box>
        </CardWithLogoXS>
    )
}

export default ResetPasswordSent
