import React, { ReactElement, useState } from 'react'

import { EmailUnverifiedNotificationAlert } from '../alert/email-unverified.notification.alert'
import { ContainerWithBottomOffset } from '../shared/mui-styled/container.styled'

import { useI18next } from 'gatsby-plugin-react-i18next'

import {
    getAccountEmail,
    getShouldDisplayEmailVerificationAlert,
    setShouldDisplayEmailVerificationAlert,
} from '../../services/auth'
import { verificationEmailHandler } from '../../services/verification.service'

import { UnverifiedEmailNotificationProps } from '../snackbar/snackbar.models'

export const UnverifiedEmailNotification = ({
    displaySnackBar,
}: UnverifiedEmailNotificationProps): ReactElement | null => {
    const { navigate } = useI18next()

    const [
        shouldDisplayEmailVerificationAlert,
        setShouldDisplayEmailVerificationAlertState,
    ] = useState(getShouldDisplayEmailVerificationAlert())

    const onEmailVerificationAlertClose = (): void => {
        setShouldDisplayEmailVerificationAlert(false)
        setShouldDisplayEmailVerificationAlertState(false)
    }

    const email = getAccountEmail()
    const buttonHandler = (): Promise<void> =>
        verificationEmailHandler(displaySnackBar, email, navigate)

    return shouldDisplayEmailVerificationAlert ? (
        <ContainerWithBottomOffset maxWidth="sm">
            <EmailUnverifiedNotificationAlert
                email={email}
                onClose={onEmailVerificationAlertClose}
                buttonHandler={buttonHandler}
            />
        </ContainerWithBottomOffset>
    ) : null
}
