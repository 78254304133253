import React, { ReactElement, useState } from 'react'
import CardXS from '../../shared/card-xs'
import { Box, Container } from '@mui/material'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import AccountMergeReviewPlatformDetails from './accountMergeReviewPlatformDetails'
import AccountMergeReviewGameDetails from './accountMergeReviewGameDetails'
import {
    accountLinkErrorUrl,
    accountLinkReviewUrl,
    accountUrl,
} from '../../../constants/url.constants'
import StepsProgressBar from '../../shared/StepsProgressBar'
import { AccountMergeReviewPlatformAccount } from './accountMergeData.models'
import { useAccountMergeData } from './accountMergeData.hook'
import {
    FormButton,
    FormLoadingButton,
    Paragraph,
    ReviewAccountList,
    TitleText,
} from './accountMerge.styled'
import { useSource } from '../../../services/util'
import { mergeAccounts, refreshUserData } from '../../../services/auth'
import { cleanSessionStorage } from '../../../services/storage.service'

const AccountMergeReview = (): ReactElement => {
    const { t } = useTranslation()
    const { navigate } = useI18next()
    const source = useSource()
    const [loading, setLoading] = useState(false)

    const [page, pageProps, profileChoice] =
        useAccountMergeData(accountLinkReviewUrl)

    const handleMerge = async (event: React.MouseEvent): Promise<void> => {
        const reviewPageProps = pageProps.review
        if (!reviewPageProps) {
            return
        }

        event.preventDefault()
        setLoading(true)
        const result = await mergeAccounts({
            source,
            token_a: reviewPageProps.accessTokenA,
            token_b: reviewPageProps.accessTokenB,
            game_profiles: profileChoice,
        })

        if (!result.errors) {
            if (result.access_token && result.refresh_token) {
                await refreshUserData(result)
            }
            cleanSessionStorage()

            navigate(accountUrl, { state: { merged: true } })
        } else {
            navigate(accountLinkErrorUrl, {
                state: {
                    error: {
                        user_id_a: pageProps.userIdA,
                        user_id_b: pageProps.userIdB,
                        game_profiles: profileChoice,
                        result,
                    },
                },
            })
        }

        setLoading(false)
    }
    return (
        <Container component="main" maxWidth="sm">
            <CardXS>
                <StepsProgressBar
                    start={page.stepStart}
                    step={page.stepThis}
                    max={page.stepTotal}
                    title={t(page.stepTitle)}
                />
                <TitleText>{t(page.title)}</TitleText>
                <Paragraph>
                    {t(
                        'After linking, these platform accounts will be associated with your CA Account:'
                    )}
                </Paragraph>
                {pageProps.review && (
                    <Box sx={{ mt: '18px' }}>
                        <ReviewAccountList>
                            <Paragraph>
                                {t(
                                    'These platforms linked with your CA Account:'
                                )}
                            </Paragraph>
                            {pageProps.review.platformAccounts.map(
                                (
                                    platformAccount: AccountMergeReviewPlatformAccount
                                ): ReactElement => (
                                    <AccountMergeReviewPlatformDetails
                                        key={platformAccount.platformAccount}
                                        platformAccount={platformAccount}
                                    />
                                )
                            )}
                        </ReviewAccountList>
                        <AccountMergeReviewGameDetails
                            reviewProps={pageProps.review}
                        />
                        <FormLoadingButton
                            type="submit"
                            color="primary"
                            variant="contained"
                            loading={loading}
                            fullWidth
                            sx={{ mt: '24px' }}
                            onClick={handleMerge}
                        >
                            {t('Complete Account Linking')}
                        </FormLoadingButton>
                        <FormButton
                            type="button"
                            color="primary"
                            variant="text"
                            fullWidth
                            sx={{ mt: '24px' }}
                            onClick={(): Promise<void> => navigate(page.prev)}
                        >
                            {t('Back')}
                        </FormButton>
                    </Box>
                )}
            </CardXS>
        </Container>
    )
}

export default AccountMergeReview
