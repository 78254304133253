import React, { ReactElement } from 'react'
import { Box, Stack, Divider } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { AccountMergePlatformIcon } from '../../platforms/icons/icons'
import { removeRefreshedPlatformData } from '../../../services/storage.service'
import { GameIcon } from '../../platforms/icons/games'
import {
    AccountMergeGame,
    AccountMergeGameProfile,
    AccountMergeGameProfileChoice,
    GameStackUri,
} from './accountMergeData.models'
import {
    CaptionMain,
    CaptionSmall,
    GameProfilesListItem,
    GameProfilesListTitle,
    GameProfilesRadio,
    GameProfilesRow,
    WarningMessage,
    WarningMessageContainer,
} from './accountMerge.styled'

const AccountMergeGameProfilesDetailsRow = ({
    gameProfile,
    gameStack,
    selected,
    onGameItemClick,
}: {
    gameProfile: AccountMergeGameProfile
    gameStack: GameStackUri
    selected: boolean
    onGameItemClick: (platformAccount: string) => void
}): ReactElement => (
    <GameProfilesRow
        direction="row"
        alignItems="center"
        selected={selected}
        onClick={(): void => onGameItemClick(gameProfile.platformAccount)}
    >
        <AccountMergePlatformIcon
            className="platformIcon"
            platformName={gameProfile.platform}
            fullColor={selected}
            fgColor="#666666"
            bgColor="#FFFFFF"
            size={24}
        />
        <Divider
            sx={{ height: '32px', my: 'auto' }}
            orientation="vertical"
            flexItem
        />
        <Stack sx={{ flexGrow: 1, gap: '4px' }}>
            <CaptionMain>{gameProfile.platformUserNickname}</CaptionMain>
            <CaptionSmall sx={{ color: '#666666' }}>
                <CaptionSmall>{gameProfile.platformLabel}</CaptionSmall>
                {gameProfile.lastPlayed && (
                    <CaptionSmall>
                        Last saved: {gameProfile.lastPlayed}
                    </CaptionSmall>
                )}
                {gameProfile.created && (
                    <CaptionSmall>
                        Date created: {gameProfile.created}
                    </CaptionSmall>
                )}
            </CaptionSmall>
        </Stack>
        <GameProfilesRadio
            checked={selected}
            value={gameProfile.platformAccount}
            name={`${gameStack}-account`}
        />
    </GameProfilesRow>
)

type AccountMergeGameProfilesDetailsProps = {
    gameData: AccountMergeGame
    profileChoice: AccountMergeGameProfileChoice
    saveProfileChoice: (profileChoice: AccountMergeGameProfileChoice) => void
    submitting: boolean
}

const AccountMergeGameProfilesDetails = ({
    gameData,
    profileChoice,
    saveProfileChoice,
    submitting,
}: AccountMergeGameProfilesDetailsProps): ReactElement => {
    removeRefreshedPlatformData()
    const { t } = useTranslation()
    const [selectedValue, setSelectedValue] = React.useState(
        profileChoice[gameData.gameStack]
    )

    const onGameItemClick = (gameAccount: string): void => {
        if (gameAccount && selectedValue !== gameAccount) {
            setSelectedValue(gameAccount)
            profileChoice[gameData.gameStack] = gameAccount
            saveProfileChoice(profileChoice)
        }
    }
    return (
        <Box sx={{ width: '100%' }}>
            <GameProfilesListItem muted={submitting && !selectedValue}>
                <GameProfilesListTitle>
                    <GameIcon
                        className="gameIcon"
                        game={gameData.game}
                        size={64}
                    />
                    <CaptionMain sx={{ flexGrow: 1 }}>
                        {gameData.gameLabel}
                    </CaptionMain>
                </GameProfilesListTitle>

                {gameData.gameProfilesCrossProgression.map(
                    (gameProfile: AccountMergeGameProfile): ReactElement => (
                        <AccountMergeGameProfilesDetailsRow
                            key={`${gameData.game}-${gameProfile.platform}`}
                            gameProfile={gameProfile}
                            gameStack={gameData.gameStack}
                            onGameItemClick={onGameItemClick}
                            selected={
                                gameProfile.platformAccount === selectedValue
                            }
                        />
                    )
                )}
            </GameProfilesListItem>
            {submitting && !selectedValue && (
                <WarningMessageContainer
                    sx={{
                        mt: '8px',
                    }}
                >
                    <Box sx={{ fontSize: '16px', m: '2px 4px' }}>
                        <InfoOutlinedIcon fontSize="inherit" />
                    </Box>
                    <WarningMessage>
                        {t(
                            'Please choose your preferred game profile to continue'
                        )}
                    </WarningMessage>
                </WarningMessageContainer>
            )}
        </Box>
    )
}

export default AccountMergeGameProfilesDetails
