import React, { ReactElement, useEffect, useMemo } from 'react'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { PageLocationProps } from '../../../models/page.models'
import { Spinner } from '../../spinner/spinner.component'
import { useAccountMergeData } from './accountMergeData.hook'
import {
    AccessTokenToBeMergedFromState,
    AccessTokensToBeMerged,
} from './accountMergeData.models'
import { accountLinkUrl } from '../../../constants/url.constants'

const AccountPreMerge = ({
    location,
}: PageLocationProps<AccessTokensToBeMerged>): ReactElement => {
    const { t } = useTranslation()
    const { navigate } = useI18next()

    const accessTokensFromState = useMemo(
        (): AccessTokenToBeMergedFromState => location.state,
        [location]
    )

    const [, pageProps] = useAccountMergeData(
        accountLinkUrl,
        accessTokensFromState
    )

    useEffect((): void => {
        if (pageProps.startingPage) {
            navigate(pageProps.startingPage)
        }
    }, [pageProps.startingPage])

    return <Spinner label={t('Just a moment...')} />
}

export default AccountPreMerge
