export enum labelI18nKeys {
    dateOfBirth = 'dateOfBirth',
    platformStatusSuccess = 'platformStatusSuccess',
    platformStatusWarning = 'platformStatusWarning',
    emailActivationStatusSuccess = 'emailActivationStatusSuccess',
    emailActivationStatusWarning = 'emailActivationStatusWarning',
    verifyEmailButton = 'verifyEmailButton',
    verifyEmailTitle = 'verifyEmailTitle',
    loginButtonLabel = 'loginButtonLabel',
    accountUnlinkedButtonLabel = 'accountUnlinkedButtonLabel',
    loginPageTitle = 'loginPageTitle',
    usernameTooltipText = 'usernameTooltipText',
    refreshRequiredTooltipText = 'refreshRequiredTooltipText',
    username = 'username',
    verificationSentTitle = 'verificationSentTitle',
    resendVerificationEmailLabel = 'resendVerificationEmailLabel',
    unverifiedEmailNotificationTitle = 'unverifiedEmailNotificationTitle',
}
