import React, { useState } from 'react'

import { PasswordProps, PasswordResult } from './password.models'
import { useTogglePasswordVisibility } from './passwordToggleVisibility.hook'

export const usePassword = (passwordProps: PasswordProps): PasswordResult => {
    const [password, setPassword] = useState('')
    const { showPassword, togglePasswordVisibility } =
        useTogglePasswordVisibility()

    const handleClickShowPassword = togglePasswordVisibility
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => event.preventDefault()

    const onPasswordChanged = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const password = event.target.value

        setPassword(password)
        passwordProps.setState(
            <StateWithPassword>(
                state: StateWithPassword
            ): StateWithPassword => ({
                ...state,
                password,
            })
        )
    }

    return {
        showPassword,
        fieldProps: {
            ...passwordProps,
            type: showPassword ? 'text' : 'password',
            value: password,
            onChange: onPasswordChanged,
        },
        btnProps: {
            onClick: handleClickShowPassword,
            onMouseDown: handleMouseDownPassword,
        },
    }
}
