import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'

import AccountLinked from '../account/accountLinked'
import AccountUnlinked from '../account/accountUnlinked'

import { PlatformDetails } from '../../models/platform.models'
import { PlatformDetailsProps } from './platform-details.models'

const getPlatformDetails = (platformDetails: PlatformDetails): ReactElement =>
    platformDetails.isPlatformConnected
        ? AccountLinked(platformDetails)
        : AccountUnlinked(platformDetails)

export const PlatformDetailsComponent = ({
    platformDetails,
}: PlatformDetailsProps): ReactElement => (
    <Grid item>{getPlatformDetails(platformDetails)}</Grid>
)
