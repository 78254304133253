import React, { useState, useEffect } from 'react'
import { Card, CardContent, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { convertTimestampToUTCDate } from '../../../services/util'
import config from '../../../config'
import { AccountDetailsPlatformIcon } from '../../platforms/icons/icons'
import { removeRefreshedPlatformData } from '../../../services/storage.service'
import { getLinkedPlatformLabels } from '../../../services/account.service'
import {
    darkGrey,
    backgroundLightGreyColor,
} from '../../../styles/colors.constants'
import { platformNames } from '../../../enums/platforms.enum'

const platformPresentation = config.platformPresentation

const AccountMergeDetails = ({ account }) => {
    removeRefreshedPlatformData()
    const theme = useTheme()
    const { t } = useTranslation()
    const styles = {
        card: {
            backgroundColor: backgroundLightGreyColor,
            marginTop: '10px',
            [theme.breakpoints.up('sm').replace('min-width', 'minWidth')]: {
                width: '536px',
            },
        },
        accountText: {
            color: darkGrey,
            wordBreak: 'break-all',
        },
    }
    const [platformName, setPlatformName] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [linkedAccounts, setLinkedAccounts] = useState('')
    const [lastLogin, setLastLogin] = useState('')

    useEffect(() => {
        if (account.email) {
            setEmail(account.email)
            if (account.nickname) {
                setUsername(account.nickname)
            }
            setPlatformName(platformPresentation.ca.platform)
            setLinkedAccounts(getLinkedPlatformLabels(account))
        } else {
            setPlatformName(platformPresentation[account.platform].platform)
        }

        if (account.platform !== platformNames.twitch) {
            setLastLogin(convertTimestampToUTCDate(account.last_login))
        }
    }, [account])

    return (
        <Card style={styles.card} elevation={0}>
            <CardContent>
                {platformName && (
                    <Grid
                        container
                        item
                        spacing={4}
                        direction="row"
                        alignItems="flex-start"
                    >
                        <Grid item xs={2.5} sm={1.5}>
                            <AccountDetailsPlatformIcon
                                platformName={platformName}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            alignItems="flex-start"
                            xs={8.5}
                            sm={10.5}
                        >
                            <Grid item>
                                <b>
                                    {platformPresentation[platformName].label}
                                </b>
                            </Grid>
                            {username && (
                                <Grid style={styles.accountText} item>
                                    {t('Username: {{username}}', { username })}
                                </Grid>
                            )}
                            {email && (
                                <Grid style={styles.accountText} item>
                                    {t('Email: {{email}}', { email })}
                                </Grid>
                            )}
                            {linkedAccounts && (
                                <Grid style={styles.accountText} item>
                                    {t('Linked Accounts: {{linkedAccounts}}', {
                                        linkedAccounts,
                                    })}
                                </Grid>
                            )}
                            {lastLogin && (
                                <Grid style={styles.accountText} item>
                                    {t('Last Logged In: {{lastLogin}}', {
                                        lastLogin,
                                    })}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    )
}

export default AccountMergeDetails
