import React, { useState } from 'react'
import {
    Box,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
} from '@mui/material'
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material/styles'
import { logoutWithDelay, updateUserInfo } from '../../services/auth'
import { useSource } from '../../services/util'
import UpdateEmailConfirm from './updateEmailConfirm'
import LoadingButton from '@mui/lab/LoadingButton'
import { CollapsableAlert } from '../alert/alert'
import {
    initialHiddenAlertState,
    useCollapsableAlert,
} from '../alert/alert.hook'
import { alertI18nKeys } from '../alert/alert.enums'
import {
    darkGrey,
    backgroundLightGreyColor,
} from '../../styles/colors.constants'
import { hasTokenErrors } from '../../services/api'

const UpdateEmail = (props) => {
    const { open, onClose, currentEmail } = props
    const theme = useTheme()
    const { navigate } = useI18next()
    const { t } = useTranslation()
    const {
        collapsableAlertProps,
        displayAlertWithI18nKey,
        displayAlertWithResponseMessage,
    } = useCollapsableAlert(initialHiddenAlertState)
    const [loading, setLoading] = useState(false)
    const [openUpdateEmailConfirmModal, setOpenUpdateEmailConfirmModal] =
        useState(false)
    const handleUpdateEmailConfirmOpen = () => {
        setOpenUpdateEmailConfirmModal(true)
    }
    const handleUpdateEmailConfirmClose = () => {
        setOpenUpdateEmailConfirmModal(false)
    }
    const [state, setState] = useState({
        email: '',
        confirmEmail: '',
        source: useSource(),
    })
    const handleClose = () => {
        collapsableAlertProps.collapseAlert()
        onClose(false)
    }
    const styles = {
        card: {
            backgroundColor: backgroundLightGreyColor,
            [theme.breakpoints.up('sm').replace('min-width', 'minWidth')]: {
                width: '536px',
                paddingLeft: '24px',
                paddingRight: '24px',
            },
        },
        emailColumn: {
            padding: '10px',
        },
        emailLabel: {
            color: darkGrey,
        },
        dialogActions: {
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingBottom: '24px',
        },
        button: {
            textTransform: 'capitalize',
        },
        dialogTitle: {
            textAlign: 'left',
            textTransform: 'capitalize',
        },
        iconButton: {
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
        },
        confirmationText: {
            color: 'black',
        },
        collapse: {
            paddingBottom: '5px',
        },
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        if (state.email !== state.confirmEmail) {
            displayAlertWithI18nKey(alertI18nKeys.emailMatchValidationError)
        } else {
            const response = await updateUserInfo({
                email: state.email,
                source: state.source,
                frontend: 'ca',
            })
            if (!response.errors) {
                handleClose()
                handleUpdateEmailConfirmOpen()
            } else {
                setLoading(false)
                if (hasTokenErrors(response.errors)) {
                    displayAlertWithI18nKey(
                        alertI18nKeys.invalidOrExpiredTokenError
                    )
                    logoutWithDelay(navigate)
                } else if (response.errors.includes('not_found')) {
                    displayAlertWithI18nKey(
                        alertI18nKeys.mergedOrDeletedAccountError
                    )
                    logoutWithDelay(navigate)
                } else {
                    displayAlertWithResponseMessage(
                        alertI18nKeys.updateEmailError,
                        response,
                        t
                    )
                }
            }
        }
        setLoading(false)
    }
    const handleUpdate = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        })
    }

    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                fullWidth={true}
                maxWidth="sm"
            >
                <Box
                    component="form"
                    method="post"
                    onSubmit={(event) => handleSubmit(event)}
                >
                    <DialogTitle style={styles.dialogTitle}>
                        <Trans>Change Email</Trans>
                        {onClose && (
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                style={styles.iconButton}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText component="div">
                            <CollapsableAlert {...collapsableAlertProps} />

                            {currentEmail && (
                                <Card elevation={0} style={styles.card}>
                                    <Grid
                                        container
                                        item
                                        direction="column"
                                        style={styles.emailColumn}
                                    >
                                        <Grid item style={styles.emailLabel}>
                                            <Trans>Current Email</Trans>
                                        </Grid>
                                        <Grid item>{currentEmail}</Grid>
                                    </Grid>
                                </Card>
                            )}
                            <TextField
                                label={t('Your New Email')}
                                type="email"
                                autoComplete="email"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                name="email"
                                onChange={handleUpdate}
                            />
                            <TextField
                                label={t('Confirm Your New Email')}
                                type="email"
                                autoComplete="email"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                name="confirmEmail"
                                onChange={handleUpdate}
                            />
                            <Grid item style={styles.confirmationText}>
                                <Trans>
                                    You will receive an email to confirm the new
                                    email address.
                                </Trans>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={styles.dialogActions}>
                        <LoadingButton
                            loading={loading}
                            variant="contained"
                            style={styles.button}
                            type="submit"
                        >
                            Verify New Email
                        </LoadingButton>
                    </DialogActions>
                </Box>
            </Dialog>
            <UpdateEmailConfirm
                open={openUpdateEmailConfirmModal}
                onClose={handleUpdateEmailConfirmClose}
                newEmail={state.email}
            />
        </Box>
    )
}

export default UpdateEmail
