export enum badgeStatuses {
    success = 'success',
    warning = 'warning',
    info = 'info',
}

export enum badgeTypes {
    platformStatus = 'platformStatus',
    emailActivationStatus = 'emailActivationStatus',
}
