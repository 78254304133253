import React from 'react'
import { Container, Card, CardContent, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { CardCALogo } from '../platforms/logo/ca-logo.styled'

const CardWithLogoXS = ({ children }) => {
    const theme = useTheme()

    const styles = {
        card: {
            borderTopWidth: '0.5rem',
            borderTopStyle: 'solid',
            borderTopColor: theme.palette.primary.main,
            [theme.breakpoints.up('sm').replace('min-width', 'minWidth')]: {
                width: '536px',
            },
        },
        cardContent: {
            padding: theme.spacing(5),
            paddingTop: theme.spacing(4),
        },
    }

    return (
        <Container component="div" maxWidth="sm">
            <Card elevation={3} style={styles.card}>
                <CardContent style={styles.cardContent}>
                    <Box display="flex" justifyContent="center" mb={1}>
                        <CardCALogo />
                    </Box>
                    {children}
                </CardContent>
            </Card>
        </Container>
    )
}

export default CardWithLogoXS
