import React, { ReactElement } from 'react'

import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import { Typography } from '@mui/material'

import CardWithLogoXS from './shared/card-with-logo-xs'

import { isLoggedIn } from '../services/auth'
import { isBrowser } from '../services/util'

import { accountUrl, loginUrl } from '../constants/url.constants'

const HomePage = (): ReactElement => {
    const { t } = useTranslation()
    const { navigate } = useI18next()

    if (isBrowser()) {
        navigate(isLoggedIn() ? accountUrl : loginUrl)
    }

    return (
        <CardWithLogoXS>
            <Typography component="h1" variant="h5">
                {t('Sign into your CA Account')}
            </Typography>
        </CardWithLogoXS>
    )
}

export default HomePage
