import {
    InfoBadgeIcon,
    SuccessBadgeIcon,
    WarningBadgeIcon,
} from './badge.styled'

import { labelI18nKeys } from '../../../enums/locales/label.enum'
import { badgeStatuses, badgeTypes } from './badge.enums'

import {
    BadgeColors,
    BadgeIconComponents,
    BadgeLabels,
    StatusBadgeLabels,
} from './badge.models'
import { darkGrey, green, yellow } from '../../../styles/colors.constants'

export const badgeIconComponents: BadgeIconComponents = {
    [badgeStatuses.success]: SuccessBadgeIcon,
    [badgeStatuses.warning]: WarningBadgeIcon,
    [badgeStatuses.info]: InfoBadgeIcon,
}

export const platformStatusBadgeLabels: StatusBadgeLabels = {
    [badgeStatuses.success]: labelI18nKeys.platformStatusSuccess,
    [badgeStatuses.warning]: labelI18nKeys.platformStatusWarning,
}

export const emailActivationStatusBadgeLabels: StatusBadgeLabels = {
    [badgeStatuses.success]: labelI18nKeys.emailActivationStatusSuccess,
    [badgeStatuses.warning]: labelI18nKeys.emailActivationStatusWarning,
}

export const badgeLabels: BadgeLabels = {
    [badgeTypes.platformStatus]: platformStatusBadgeLabels,
    [badgeTypes.emailActivationStatus]: emailActivationStatusBadgeLabels,
}
export const badgeColors: BadgeColors = {
    [badgeStatuses.success]: green,
    [badgeStatuses.warning]: yellow,
    [badgeStatuses.info]: darkGrey,
}
