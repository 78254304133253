import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import { Route } from '../../components/route/route'
import RegisterConfirm from '../../components/register/registerConfirm'

const RegisterConfirmPage = (): ReactElement => (
    <Route component={RegisterConfirm} pageTitle="Registration Successful" />
)

export default RegisterConfirmPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
