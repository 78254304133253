import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import PublicRoute from '../../components/route/publicRoute'
import Register from '../../components/register/emailRegister'

import { PageLocationProps } from '../../models/page.models'

const RegisterPage = (pageProps: PageLocationProps): ReactElement => (
    <PublicRoute
        component={Register}
        pageTitle="Register"
        pageProps={pageProps}
    />
)

export default RegisterPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
