import { useState } from 'react'

import { getComponents, getSeverity } from './snackbar.service'

import {
    initialSnackBarState,
    snackBarComponentIcons,
} from './snackbar.constants'

import {
    DisplaySnackBarProps,
    SnackBarComponentState,
    UseSnackBarComponent,
} from './snackbar.models'

export const useSnackBarComponent = (): UseSnackBarComponent => {
    const [snackBarState, setSnackBarState] =
        useState<SnackBarComponentState>(initialSnackBarState)

    const displaySnackBar = ({
        severity,
        icon,
        labelI18nTitleKey,
        alertI18nMessageKey,
        values,
    }: DisplaySnackBarProps): void =>
        setSnackBarState({
            values,
            labelI18nTitleKey,
            alertI18nMessageKey,
            open: true,
            icon: icon && snackBarComponentIcons[icon],
            severity: getSeverity(severity, alertI18nMessageKey),
            components: getComponents(alertI18nMessageKey),
        })

    const hideSnackbar = (): void =>
        setSnackBarState({ ...snackBarState, open: false })

    return { displaySnackBar, hideSnackbar, snackBarState }
}
