import React, { useState } from 'react'
import CardWithLogoXS from '../shared/card-with-logo-xs'
import { Box, TextField, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { useQuery } from '../../services/util'
import { resetPasswordSubmit } from '../../services/auth'
import { CollapsableAlert } from '../alert/alert'
import {
    initialHiddenAlertState,
    useCollapsableAlert,
} from '../alert/alert.hook'
import { alertI18nKeys } from '../alert/alert.enums'
import { loginUrl } from '../../constants/url.constants'

const ResetPasswordSubmit = () => {
    const { t } = useTranslation()
    const { navigate } = useI18next()
    const query = useQuery()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { collapsableAlertProps, displayAlertWithResponseMessage } =
        useCollapsableAlert(initialHiddenAlertState)
    const [loading, setLoading] = React.useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault()
        collapsableAlertProps.collapseAlert()
        setLoading(true)
        const token = query.get('token').replace(/^\['(.*)'\]$/, '$1')

        const response = await resetPasswordSubmit({ email, password, token })

        if (response.result === 'password_updated') {
            navigate(loginUrl, {
                state: { passwordReset: true },
            })
        } else {
            displayAlertWithResponseMessage(
                alertI18nKeys.resetPasswordError,
                response,
                t
            )
        }
        setLoading(false)
    }

    return (
        <CardWithLogoXS>
            <Typography component="h1" variant="h5">
                {t('Update Your Password')}
            </Typography>

            <CollapsableAlert {...collapsableAlertProps} />

            <Box
                component="form"
                method="post"
                onSubmit={(event) => handleSubmit(event)}
            >
                <TextField
                    label={t('Email Address')}
                    type="email"
                    autoComplete="email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    name="email"
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value)
                    }}
                />
                <TextField
                    label="Password"
                    type="password"
                    autoComplete="new-password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    name="password"
                    onChange={(event) => {
                        setPassword(event.target.value)
                    }}
                />
                <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={loading}
                    fullWidth
                >
                    {t('Reset')}
                </LoadingButton>
            </Box>
        </CardWithLogoXS>
    )
}

export default ResetPasswordSubmit
