import React, { ReactElement } from 'react'
import { TextField } from '@mui/material'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { getLabelI18nValue } from '../../../services/i18n.service'
import { useUsername } from './username.hook'

import { inputProps } from '../../../constants/username.constants'

import { labelI18nKeys } from '../../../enums/locales/label.enum'
import { UsernameProps } from './username.models'

export const Username = (usernameProps: UsernameProps): ReactElement => {
    const { t } = useTranslation()
    const { username, onChange } = useUsername(usernameProps)

    return (
        <TextField
            label={getLabelI18nValue(t, labelI18nKeys.username)}
            type="text"
            autoComplete="username"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            value={username}
            name="nickname"
            onChange={onChange}
            inputProps={inputProps}
        />
    )
}
