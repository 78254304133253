import React, { useEffect, useState, useRef } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import CardWithLogoXS from '../shared/card-with-logo-xs'
import OptIn from '../shared/optin'
import { DateOfBirth } from '../shared/date-of-birth/date-of-birth'
import { useTheme } from '@mui/material/styles'
import { Trans, useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import { handleRegister } from '../../services/registration'
import { setCheckboxValue, useQuery, useSource } from '../../services/util'
import { parseAndStoreOauthData } from '../../services/oauth'
import { CollapsableAlert } from '../alert/alert'
import {
    initialHiddenAlertState,
    useCollapsableAlert,
} from '../alert/alert.hook'
import { alertI18nKeys } from '../alert/alert.enums'
import { handleLogin } from '../../services/auth'
import { getAlertI18nKeyFromResponse } from '../alert/alert.services'
import { AppLink } from '../link/app.link'
import {
    accountUrl,
    loginUrl,
    privacyPolicyInRegistrationUrl,
} from '../../constants/url.constants'
import { Username } from '../shared/username/username'
import { isBrowser } from '../../services/util'
import config from '../../config'
import ReCAPTCHA from 'react-google-recaptcha'

const EmailRegister = () => {
    const theme = useTheme()
    const { navigate } = useI18next()
    const { t } = useTranslation()
    const {
        collapsableAlertProps,
        displayAlertWithI18nKey,
        displayAlertWithResponseMessage,
    } = useCollapsableAlert(initialHiddenAlertState)
    const [loading, setLoading] = useState(false)
    const query = useQuery()
    const [state, setState] = useState({
        nickname: '',
        email: '',
        password: '',
        dateofbirth: null,
        privacy_policy: false,
        accepts_marketing: false,
        source: useSource(),
    })

    const styles = {
        formButton: {
            marginTop: theme.spacing(2),
        },
        label: {
            fontSize: '0.75rem',
            lineHeight: '0.1em',
            fontFamily: 'Inter',
            fontWeight: '400',
        },
    }

    const recaptchaRef = useRef()
    const handleSubmitWithReCAPTCHA = async (event) => {
        event.preventDefault()

        if (!state.privacy_policy) {
            displayAlertWithI18nKey(
                alertI18nKeys.termsOfServiceAreNotAcceptedError
            )
        } else if (state.password.length < 8) {
            displayAlertWithI18nKey(alertI18nKeys.passwordValidationError)
        } else if (!state.dateofbirth) {
            displayAlertWithI18nKey(alertI18nKeys.dateOfBirthRequiredError)
        } else {
            recaptchaRef.current.reset()
            recaptchaRef.current
                .executeAsync()
                .then(async (gRecaptchaResponse) => {
                    setLoading(true)

                    const response = await handleRegister({
                        ...state,
                        gRecaptchaResponse,
                    })

                    if (!response.errors) {
                        const result = await handleLogin(state)

                        if (!response.errors) {
                            return navigate(accountUrl)
                        }

                        return navigate(loginUrl, {
                            state: {
                                alertI18nKey:
                                    getAlertI18nKeyFromResponse(result),
                            },
                        })
                    } else {
                        displayAlertWithResponseMessage(
                            alertI18nKeys.unknownRegistrationError,
                            response,
                            t
                        )
                    }
                    setLoading(false)
                })
        }
    }

    const handleUpdate = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value.trim(),
        })
    }

    useEffect(() => {
        parseAndStoreOauthData(query)
    }, [query])

    // Signal recaptcha plugin to load js from recaptcha.net
    if (isBrowser()) {
        window.recaptchaOptions = {
            useRecaptchaNet: true,
        }
    }

    return (
        <CardWithLogoXS>
            <Typography component="h1" variant="h5">
                {t('Create your CA Account')}
            </Typography>

            <CollapsableAlert {...collapsableAlertProps} />

            <Box
                component="form"
                method="post"
                onSubmit={(event) => handleSubmitWithReCAPTCHA(event)}
            >
                <Username setState={setState} />
                <TextField
                    label={t('Email Address')}
                    type="email"
                    autoComplete="email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    name="email"
                    onChange={handleUpdate}
                />
                <TextField
                    label={t('Password')}
                    type="password"
                    autoComplete="new-password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    name="password"
                    onChange={handleUpdate}
                />
                <DateOfBirth setState={setState} />
                <OptIn handleUpdate={setCheckboxValue(setState)} />

                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    badge="bottomleft"
                    sitekey={config.recaptchaPublicKey}
                />
                <Box display="flex" justifyContent="center" mt={2}>
                    <Typography variant="small" gutterBottom>
                        <Trans>
                            <span style={styles.label}>
                                Please read and understand our{' '}
                            </span>
                            <AppLink
                                style={styles.label}
                                to={privacyPolicyInRegistrationUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Privacy Policy
                            </AppLink>
                            <span style={styles.label}>
                                {' '}
                                which sets out information about our data
                                privacy practices.
                            </span>
                        </Trans>
                    </Typography>
                </Box>
                <LoadingButton
                    style={styles.formButton}
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={loading}
                    fullWidth
                >
                    <Trans>Next</Trans>
                </LoadingButton>
            </Box>
            <Box display="flex" justifyContent="center" mt={2}>
                <Typography variant="small" gutterBottom>
                    <Trans>
                        Already have an account?{' '}
                        <AppLink to={loginUrl}>Login</AppLink>
                    </Trans>
                </Typography>
            </Box>
        </CardWithLogoXS>
    )
}

export default EmailRegister
