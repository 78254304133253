import styled from '@emotion/styled'
import { Box, Button } from '@mui/material'
import { ButtonProps } from '@mui/material/Button/Button'

import { buttonSeverityColors } from './button.constants'

import { alertSeverities } from '../alert/alert.enums'

import { PlatformIconProps } from '../platforms/icons/icons.models'
import { StyledLoginButtonProps } from './button.models'

import config from '../../config'

const platformPresentation = config.platformPresentation

export const RefreshStyledButton = styled(Button)`
    text-transform: capitalize;
    font-weight: 500;
    float: right;
`

export const AlertStyledButton = styled(Button)`
    display: block;
    text-transform: none;
    font-weight: bold;
    color: ${({ color }: ButtonProps): string =>
        buttonSeverityColors[color as alertSeverities]}; // TODO: CLS-11048
`

export const PlatformIconContainer = styled(Box)`
    background-color: ${({ platformName }: PlatformIconProps): string =>
        platformPresentation[platformName].color};
    @media (max-width: 300px) {
        height: 63px;
    }
    width: 63px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const StyledLoginButton = styled(Button, {
    shouldForwardProp: (prop: string): boolean => prop !== 'isLoginPage',
})`
    border-color: #e5e5e5;
    border-width: 2px;
    color: #1a1a1a;
    max-width: ${({ isLoginPage }: StyledLoginButtonProps): string =>
        isLoginPage ? '320px' : '280px'};
    width: 100%;
    text-transform: none;
    justify-content: start;
    padding-left: 4px;
    font-size: 12px;
    padding-top: 0px;
    padding-bottom: 0px;
    :hover {
        border-color: #e5e5e5;
        border-width: 2px;
    }
`
