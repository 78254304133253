import React, { useState, useEffect } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { Typography, Box, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import CardWithLogoXS from '../shared/card-with-logo-xs'
import OptIn from '../shared/optin'
import { DateOfBirth } from '../shared/date-of-birth/date-of-birth'
import { useTheme, styled } from '@mui/material/styles'
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import {
    updateUserInfo,
    handleLogin,
    logoutWithDelay,
} from '../../services/auth'
import { setCheckboxValue, useSource } from '../../services/util'
import { getUser, hasEmail } from '../../services/auth'
import { hasTokenErrors } from '../../services/api'
import { CollapsableAlert } from '../alert/alert'
import { alertI18nKeys } from '../alert/alert.enums'
import {
    useCollapsableAlert,
    initialHiddenAlertState,
} from '../alert/alert.hook'
import { BrowserOnly } from '../utils/utils'
import { Username } from '../shared/username/username'
import { Password } from '../shared/password/password'
import { AppLink } from '../link/app.link'
import {
    accountUrl,
    accountConfirmUrl,
    confirmRegisterUrl,
    accountLinkUrl,
    privacyPolicyInRegistrationUrl,
} from '../../constants/url.constants'
import PlatformInfoBar from '../shared/platformInfoBar'

const CompleteRegisterForm = styled(Box)`
    padding-top: 15px;
`
const FormButton = styled(LoadingButton)`
    margin-top: ${(props) => props.theme.spacing(2)};
`
const PlatformInfoBarContainer = styled(Box)`
    margin: 5px 0;
`
const LabelSpan = styled(Box)`
    font-size: 0.75rem;
    line-height: 0.1em;
    font-family: Inter;
    font-weight: 400;
`
const LabelAppLink = styled(AppLink)`
    font-size: 0.75rem;
    line-height: 0.1em;
    font-family: Inter;
    font-weight: 400;
`
const CompleteRegister = () => {
    const theme = useTheme()
    const { navigate } = useI18next()
    const source = useSource()
    const { t } = useTranslation()
    const {
        collapsableAlertProps,
        displayAlertWithResponseMessage,
        displayAlertWithI18nKey,
    } = useCollapsableAlert(initialHiddenAlertState)

    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        nickname: '',
        email: '',
        password: '',
        dateofbirth: null,
        privacy_policy: false,
        accepts_marketing: false,
        source: source,
    })

    const {
        shortNickname,
        email,
        password,
        dateofbirth,
        privacy_policy,
        accepts_marketing,
        isPlatformAccount,
    } = getUser()

    useEffect(() => {
        setState({
            nickname: shortNickname,
            email,
            password,
            dateofbirth,
            privacy_policy,
            accepts_marketing,
        })
    }, [source])

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        const user = getUser()
        collapsableAlertProps.collapseAlert()

        if (!state.privacy_policy) {
            displayAlertWithI18nKey(
                alertI18nKeys.termsOfServiceAreNotAcceptedError
            )
        } else if (!state.dateofbirth) {
            displayAlertWithI18nKey(alertI18nKeys.dateOfBirthRequiredError)
        } else {
            const response = await updateUserInfo({
                ...state,
                frontend: 'ca',
                source,
            })

            if (!response.errors) {
                if (hasEmail()) {
                    navigate(accountUrl)
                } else {
                    return navigate(confirmRegisterUrl)
                }
            } else if (response.errors?.includes('user_exists')) {
                const loginResponse = await handleLogin({
                    email: state.email,
                    password: state.password,
                    source,
                    skipRefresh: true,
                })

                if (loginResponse.access_token) {
                    navigate(accountLinkUrl, {
                        state: {
                            accessTokenA: loginResponse.access_token,
                            accessTokenB: user.access_token,
                            refreshTokenA: loginResponse.refresh_token,
                            refreshTokenB: user.refresh_token,
                        },
                    })
                } else {
                    navigate(accountConfirmUrl, {
                        state: {
                            email: state.email,
                            mergeAccessToken: user.access_token,
                            mergeRefreshToken: user.refresh_token,
                        },
                    })
                }
            } else if (hasTokenErrors(response.errors)) {
                displayAlertWithI18nKey(
                    alertI18nKeys.invalidOrExpiredTokenError
                )
                logoutWithDelay(navigate)
            } else {
                displayAlertWithResponseMessage(
                    alertI18nKeys.unknownRegistrationError,
                    response,
                    t
                )
            }
        }
        setLoading(false)
    }

    const handleUpdate = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value.trim(),
        })
    }

    return (
        <CardWithLogoXS>
            <Typography component="h1" variant="h5">
                {t('Complete Your Profile')}
            </Typography>
            <Box textAlign="center">
                <Typography variant="small">
                    {t('We need a little more information.')}
                </Typography>
            </Box>

            <CollapsableAlert {...collapsableAlertProps} />
            <BrowserOnly>
                <CompleteRegisterForm
                    component="form"
                    method="post"
                    onSubmit={(event) => handleSubmit(event)}
                >
                    {isPlatformAccount && (
                        <PlatformInfoBarContainer>
                            <PlatformInfoBar />
                        </PlatformInfoBarContainer>
                    )}
                    <Username
                        initialUsername={shortNickname}
                        setState={setState}
                    />
                    <TextField
                        label={t('Email Address')}
                        type="email"
                        autoComplete="email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        name="email"
                        value={state.email}
                        onChange={handleUpdate}
                    />
                    {isPlatformAccount ? null : (
                        <Password
                            label={t('Password')}
                            name="password"
                            autoComplete="new-password"
                            setState={setState}
                        />
                    )}
                    <DateOfBirth setState={setState} />
                    <OptIn handleUpdate={setCheckboxValue(setState)} />
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Typography variant="small" gutterBottom>
                            <Trans>
                                <LabelSpan component="span">
                                    Please read and understand our{' '}
                                </LabelSpan>
                                <LabelSpan component="span">
                                    <AppLink
                                        to={privacyPolicyInRegistrationUrl}
                                        rel="noopener"
                                        target="_blank"
                                    >
                                        Privacy Policy
                                    </AppLink>
                                </LabelSpan>
                                <LabelSpan component="span">
                                    {' '}
                                    which sets out information about our data
                                    privacy practices.
                                </LabelSpan>
                            </Trans>
                        </Typography>
                    </Box>
                    <FormButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        loading={loading}
                        theme={theme}
                        fullWidth
                    >
                        <Trans>Next</Trans>
                    </FormButton>
                </CompleteRegisterForm>
            </BrowserOnly>
        </CardWithLogoXS>
    )
}

export default CompleteRegister
