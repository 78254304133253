import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import { PlatformRoute } from '../../components/route/route'

import { platformNames } from '../../enums/platforms.enum'

const SteamLoginPage = (): ReactElement => (
    <PlatformRoute platformName={platformNames.steam} pageTitle="Steam Login" />
)

export default SteamLoginPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
