import styled from '@emotion/styled'

import CheckIcon from '@mui/icons-material/Check'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { css, Grid } from '@mui/material'

const badgeIconStyles = css`
    width: 20px;
    height: 20px;
    position: relative;
    top: 1px;
`
export const SuccessBadgeIcon = styled(CheckIcon)`
    ${badgeIconStyles}
`

export const WarningBadgeIcon = styled(InfoOutlinedIcon)`
    ${badgeIconStyles}
`

export const InfoBadgeIcon = styled(InfoOutlinedIcon)`
    ${badgeIconStyles}
`

export type BadgeGridContainerProps = {
    color: string
}

export const BadgeGridContainer = styled(Grid)`
    color: ${({ color }: BadgeGridContainerProps): string => color};
    display: flex;
    align-items: center;
`

export const LabelContainer = styled(Grid)`
    margin-left: 5px;
`
