import React, { useState } from 'react'
import {
    Box,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material'
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material/styles'
import { DateOfBirth } from '../shared/date-of-birth/date-of-birth'
import { logoutWithDelay, updateUserInfo } from '../../services/auth'
import { useSource } from '../../services/util'
import { convertToLocaleDateString } from '../../services/dates.service'
import LoadingButton from '@mui/lab/LoadingButton'
import { CollapsableAlert } from '../alert/alert'
import {
    initialHiddenAlertState,
    useCollapsableAlert,
} from '../alert/alert.hook'
import { alertI18nKeys } from '../alert/alert.enums'
import {
    darkGrey,
    backgroundLightGreyColor,
} from '../../styles/colors.constants'
import { hasTokenErrors } from '../../services/api'

const UpdateDateOfBirth = (props) => {
    const { open, onClose, currentDateOfBirth, alertCallback } = props
    const theme = useTheme()
    const { navigate } = useI18next()
    const { t } = useTranslation()
    const {
        collapsableAlertProps,
        displayAlertWithI18nKey,
        displayAlertWithResponseMessage,
    } = useCollapsableAlert(initialHiddenAlertState)
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        dateofbirth: null,
        source: useSource(),
    })
    const handleClose = () => {
        collapsableAlertProps.collapseAlert()
        onClose(false)
    }
    const styles = {
        card: {
            backgroundColor: backgroundLightGreyColor,
            [theme.breakpoints.up('sm').replace('min-width', 'minWidth')]: {
                width: '536px',
            },
        },
        dateOfBirthColumn: {
            padding: '10px',
        },
        dateOfBirthLabel: {
            color: darkGrey,
        },
        dialogActions: {
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingBottom: '24px',
        },
        button: {
            textTransform: 'capitalize',
        },
        dialogTitle: {
            textAlign: 'left',
            textTransform: 'none',
        },
        iconButton: {
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
        },
        collapse: {
            paddingBottom: '5px',
        },
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)

        const { dateofbirth, source } = state
        if (!dateofbirth) {
            displayAlertWithI18nKey(alertI18nKeys.dateOfBirthRequiredError)
        } else {
            const response = await updateUserInfo({ dateofbirth, source })

            if (!response.errors) {
                handleClose()
                alertCallback('date of birth')
            } else {
                setLoading(false)
                if (hasTokenErrors(response.errors)) {
                    displayAlertWithI18nKey(
                        alertI18nKeys.invalidOrExpiredTokenError
                    )
                    logoutWithDelay(navigate)
                } else if (response.errors.includes('not_found')) {
                    displayAlertWithI18nKey(
                        alertI18nKeys.mergedOrDeletedAccountError
                    )
                    logoutWithDelay(navigate)
                } else {
                    displayAlertWithResponseMessage(
                        alertI18nKeys.updateDateOfBirthError,
                        response,
                        t
                    )
                }
            }
        }
        setLoading(false)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth="sm"
        >
            <Box
                component="form"
                method="post"
                onSubmit={(event) => handleSubmit(event)}
            >
                <DialogTitle style={styles.dialogTitle}>
                    <Trans>Change Date of Birth</Trans>
                    {onClose && (
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            style={styles.iconButton}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component="div">
                        <CollapsableAlert {...collapsableAlertProps} />

                        {currentDateOfBirth && (
                            <Card elevation={0} style={styles.card}>
                                <Grid
                                    container
                                    item
                                    direction="column"
                                    style={styles.dateOfBirthColumn}
                                >
                                    <Grid item style={styles.dateOfBirthLabel}>
                                        <Trans>Current Date of Birth</Trans>
                                    </Grid>
                                    <Grid item>
                                        {convertToLocaleDateString(
                                            currentDateOfBirth
                                        )}
                                    </Grid>
                                </Grid>
                            </Card>
                        )}
                        <DateOfBirth setState={setState} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={styles.dialogActions}>
                    <LoadingButton
                        loading={loading}
                        variant="contained"
                        style={styles.button}
                        type="submit"
                    >
                        Update Date of Birth
                    </LoadingButton>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default UpdateDateOfBirth
