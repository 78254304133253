import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import PrivateRoute from '../../../components/route/privateRoute'
import AccountMergeError from '../../../components/account/link/accountMergeError'

import { PageLocationProps } from '../../../models/page.models'

const MergeAccountErrorPage = (pageProps: PageLocationProps): ReactElement => (
    <PrivateRoute
        component={AccountMergeError}
        pageTitle="Account Linking Error"
        pageProps={pageProps}
    />
)

export default MergeAccountErrorPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
