import { get } from './api'
import { isBrowser } from './util'
import { getUser } from './auth'

export const getOauthData = () =>
    isBrowser() && localStorage.getItem('caAccountOauth')
        ? JSON.parse(localStorage.getItem('caAccountOauth'))
        : {}

export const setOauthData = (data) =>
    localStorage.setItem('caAccountOauth', JSON.stringify(data))

export const parseAndStoreOauthData = (searchParams) => {
    const oauthParams = {}
    searchParams.forEach((v, k) => (oauthParams[k] = v))
    if ('client_id' in oauthParams) {
        setOauthData(oauthParams)
    }
}

export const oauthAuthorization = ({
    client_id,
    redirect_uri,
    response_type,
    scope,
}) => {
    const { refresh_token } = getUser()

    return get(
        `oauth/authorize_decoupled/?client_id=${client_id}&response_type=${response_type}&scope=${scope}&redirect_uri=${encodeURIComponent(
            redirect_uri
        )}`,
        {
            headers: { 'X-CA-Token': refresh_token },
        }
    )
}
