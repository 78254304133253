import React, { useState } from 'react'
import {
    Box,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material'
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material/styles'
import { logoutWithDelay, updateUserInfo } from '../../services/auth'
import { useSource } from '../../services/util'
import LoadingButton from '@mui/lab/LoadingButton'
import { CollapsableAlert } from '../alert/alert'
import {
    initialHiddenAlertState,
    useCollapsableAlert,
} from '../alert/alert.hook'
import { alertI18nKeys } from '../alert/alert.enums'
import {
    darkGrey,
    backgroundLightGreyColor,
} from '../../styles/colors.constants'
import { Username } from '../shared/username/username'
import { hasTokenErrors } from '../../services/api'

const UpdateUsername = (props) => {
    const { open, onClose, currentUsername, alertCallback } = props
    const theme = useTheme()
    const { navigate } = useI18next()
    const { t } = useTranslation()
    const {
        collapsableAlertProps,
        displayAlertWithI18nKey,
        displayAlertWithResponseMessage,
    } = useCollapsableAlert(initialHiddenAlertState)
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        nickname: '',
        source: useSource(),
    })
    const handleClose = () => {
        collapsableAlertProps.collapseAlert()
        onClose(false)
    }
    const styles = {
        card: {
            backgroundColor: backgroundLightGreyColor,
            [theme.breakpoints.up('sm').replace('min-width', 'minWidth')]: {
                width: '536px',
                paddingLeft: '24px',
                paddingRight: '24px',
            },
        },
        usernameColumn: {
            padding: '10px',
        },
        usernameLabel: {
            color: darkGrey,
        },
        dialogActions: {
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingBottom: '24px',
        },
        button: {
            textTransform: 'capitalize',
        },
        dialogTitle: {
            textAlign: 'left',
            textTransform: 'capitalize',
        },
        iconButton: {
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
        },
        collapse: {
            paddingBottom: '5px',
        },
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        const response = await updateUserInfo({
            nickname: state.nickname,
            source: state.source,
        })
        if (!response.errors) {
            handleClose()
            alertCallback('username')
        } else {
            setLoading(false)
            if (hasTokenErrors(response.errors)) {
                displayAlertWithI18nKey(
                    alertI18nKeys.invalidOrExpiredTokenError
                )
                logoutWithDelay(navigate)
            } else if (response.errors.includes('not_found')) {
                displayAlertWithI18nKey(
                    alertI18nKeys.mergedOrDeletedAccountError
                )
                logoutWithDelay(navigate)
            } else {
                displayAlertWithResponseMessage(
                    alertI18nKeys.updateUserNameError,
                    response,
                    t
                )
            }
        }
        setLoading(false)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth="sm"
        >
            <Box
                component="form"
                method="post"
                onSubmit={(event) => handleSubmit(event)}
            >
                <DialogTitle style={styles.dialogTitle}>
                    <Trans>Change Username</Trans>
                    {onClose && (
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            style={styles.iconButton}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component="div">
                        <CollapsableAlert {...collapsableAlertProps} />

                        <Card elevation={0} style={styles.card}>
                            <Grid
                                container
                                item
                                direction="column"
                                style={styles.usernameColumn}
                            >
                                <Grid item style={styles.usernameLabel}>
                                    <Trans>Current Username</Trans>
                                </Grid>
                                <Grid item>{currentUsername}</Grid>
                            </Grid>
                        </Card>
                        <Username setState={setState} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={styles.dialogActions}>
                    <LoadingButton
                        loading={loading}
                        variant="contained"
                        style={styles.button}
                        type="submit"
                    >
                        Update Username
                    </LoadingButton>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default UpdateUsername
