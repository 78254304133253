import React from 'react'
import CardWithLogoXS from '../shared/card-with-logo-xs'
import { Box, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { useQuery, useSource } from '../../services/util'
import {
    getUser,
    isLoggedInWithRefresh,
    refreshUserData,
    getRedirectUrlAfterVerifiedEmail,
} from '../../services/auth'
import { verifyEmail } from '../../services/registration'
import { CollapsableAlert } from '../alert/alert'
import {
    initialHiddenAlertState,
    useCollapsableAlert,
} from '../alert/alert.hook'
import { alertI18nKeys } from '../alert/alert.enums'
import { labelI18nKeys } from '../../enums/locales/label.enum'

const EmailConfirm = () => {
    const { t } = useTranslation()
    const { navigate } = useI18next()
    const query = useQuery()
    const source = useSource()
    const { collapsableAlertProps, displayAlertWithResponseMessage } =
        useCollapsableAlert(initialHiddenAlertState)
    const [loading, setLoading] = React.useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault()
        collapsableAlertProps.collapseAlert()
        setLoading(true)
        const token = query.get('token').replace(/^\['(.*)'\]$/, '$1')

        const response = await verifyEmail({ token, source })

        if (response.result === 'verified') {
            const redirectUrl = getRedirectUrlAfterVerifiedEmail(
                await isLoggedInWithRefresh()
            )
            navigate(redirectUrl, {
                state: { emailConfirmed: true },
            })
        } else {
            displayAlertWithResponseMessage(
                alertI18nKeys.unknownEmailActivationError,
                response,
                t
            )
        }
        setLoading(false)
    }

    return (
        <CardWithLogoXS>
            <Typography component="h1" variant="h5">
                {t(labelI18nKeys.verifyEmailTitle)}
            </Typography>

            <CollapsableAlert {...collapsableAlertProps} />

            <Box
                display="flex"
                justifyContent="center"
                mb={3}
                component="form"
                method="post"
                onSubmit={(event) => handleSubmit(event)}
            >
                <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={loading}
                    fullWidth
                >
                    {t(labelI18nKeys.verifyEmailButton)}
                </LoadingButton>
            </Box>
        </CardWithLogoXS>
    )
}

export default EmailConfirm
