import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import PrivateRoute from '../../../components/route/privateRoute'
import AccountMergeReview from '../../../components/account/link/accountMergeReview'

import { PageLocationProps } from '../../../models/page.models'

const MergeAccountPage = (pageProps: PageLocationProps): ReactElement => (
    <PrivateRoute
        component={AccountMergeReview}
        pageTitle="Link Your Accounts"
        pageProps={pageProps}
    />
)

export default MergeAccountPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
