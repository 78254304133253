import React, { ReactElement } from 'react'

import { DialogContent, Snackbar } from '@mui/material'

import { AlertComponent } from '../alert/alert'

import {
    anchorOriginConfig,
    autoHideDurationInMs,
} from '../alert/alert.constants'

import { alertVariants } from '../alert/alert.enums'

import { SnackBarComponentProps } from './snackbar.models'

export const SnackBarComponent = ({
    open,
    anchorOrigin,
    hideSnackbar,
    ...alertComponentProps
}: SnackBarComponentProps): ReactElement => (
    <Snackbar
        open={open}
        onClose={hideSnackbar}
        anchorOrigin={anchorOrigin || anchorOriginConfig}
        autoHideDuration={autoHideDurationInMs}
    >
        <DialogContent>
            <AlertComponent
                variant={alertVariants.filled}
                onClose={hideSnackbar}
                {...alertComponentProps}
            />
        </DialogContent>
    </Snackbar>
)
