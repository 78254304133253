import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import { Route } from '../../components/route/route'
import EmailConfirm from '../../components/register/emailConfirm'

const RegisterActivatePage = (): ReactElement => (
    <Route component={EmailConfirm} pageTitle="Activate Your Account" />
)

export default RegisterActivatePage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
