import React, { ReactElement } from 'react'

import { AlertComponent } from './alert'
import { BoldText } from '../shared/text-field/text-field'

import { alertI18nKeys, alertSeverities } from './alert.enums'
import { labelI18nKeys } from '../../enums/locales/label.enum'

import { EmailAlertProps } from './alert.models'

export const EmailUnverifiedAlert = ({
    email,
    buttonHandler,
}: EmailAlertProps): ReactElement => (
    <AlertComponent
        severity={alertSeverities.warning}
        values={{ email }}
        components={[<BoldText key={email} />]}
        alertI18nMessageKey={alertI18nKeys.verifyAccountEmailMessage}
        buttonHandler={buttonHandler}
        labelI18nButtonLabelKey={labelI18nKeys.resendVerificationEmailLabel}
    />
)
