import {
    errorSeverityLightColor,
    infoSeverityLightColor,
    successSeverityLightColor,
    warningSeverityLightColor,
} from '../../styles/colors.constants'

import { alertSeverities } from '../alert/alert.enums'

import { ButtonSeverityColors } from './button.models'

export const buttonSeverityColors: ButtonSeverityColors = {
    [alertSeverities.warning]: warningSeverityLightColor,
    [alertSeverities.info]: infoSeverityLightColor,
    [alertSeverities.success]: successSeverityLightColor,
    [alertSeverities.error]: errorSeverityLightColor,
}
