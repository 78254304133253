import React, { useCallback, useState } from 'react'

import { UsernameResult, UsernameProps } from './username.models'

export const useUsername = ({
    initialUsername,
    setState,
}: UsernameProps): UsernameResult => {
    const [username, setUsername] = useState(initialUsername)

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            const nickname = event.target.value.trim()

            setUsername(nickname)
            setState(
                <StateWithUsername>(
                    state: StateWithUsername
                ): StateWithUsername => ({
                    ...state,
                    nickname,
                })
            )
        },
        [setState]
    )

    return { username, onChange }
}
