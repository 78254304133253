import React, { useState, useEffect, ReactElement } from 'react'
import styled from '@emotion/styled'
import { Typography, Box, Button } from '@mui/material'
import CardWithLogoXS from '../../shared/card-with-logo-xs'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTheme } from '@mui/material/styles'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import {
    mergeAccounts,
    refreshUserData,
    validateToken,
} from '../../../services/auth'
import { AccountData } from '../../../models/account.models'
import AccountMergeDetails from './accountMergeDetails'
import { useSource } from '../../../services/util'
import {
    accountUrl,
    accountLinkErrorUrl,
} from '../../../constants/url.constants'
import { getAccountInfo } from '../../../services/account'
import {
    cleanSessionStorage,
    getSessionStorageByKey,
    sessionStorageKeys,
    setSessionStorageByKey,
} from '../../../services/storage.service'
import { RegularRequestRetryError } from '../../../models/common.models'
import { PageLocationProps } from '../../../models/page.models'
import {
    AccessTokenToBeMergedFromState,
    AccessTokensToBeMerged,
} from './accountMergeData.models'

type AccountMergeData = {
    accountA?: AccountData
    accountB?: AccountData
    mergeAccessTokenA: string
    mergeAccessTokenB: string
    showAccountDetails: boolean
}

const AccountMerge = ({
    location,
}: PageLocationProps<AccessTokensToBeMerged>): ReactElement => {
    const theme = useTheme()
    const { t } = useTranslation()
    const { navigate } = useI18next()
    const source = useSource()
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState<AccountMergeData>({
        mergeAccessTokenA: '',
        mergeAccessTokenB: '',
        showAccountDetails: false,
    })

    useEffect((): void => {
        const fetchData = async (): Promise<void> => {
            const mergeTokensFromState: AccessTokenToBeMergedFromState =
                location.state
            let mergeTokens = mergeTokensFromState

            if (!mergeTokens?.accessTokenA || !mergeTokens?.accessTokenB) {
                mergeTokens =
                    getSessionStorageByKey<AccessTokensToBeMerged>(
                        sessionStorageKeys.mergeTokens
                    ) || undefined
                if (!mergeTokens?.accessTokenA || !mergeTokens?.accessTokenB) {
                    navigate(accountLinkErrorUrl)
                    return
                }
            }

            setSessionStorageByKey(sessionStorageKeys.mergeTokens, mergeTokens)

            const [tokenDetailsA, tokenDetailsB] = await Promise.all([
                validateToken(mergeTokens.accessTokenA),
                validateToken(mergeTokens.accessTokenB),
            ])

            // mergeTokens possibly refreshed
            mergeTokens =
                getSessionStorageByKey<AccessTokensToBeMerged>(
                    sessionStorageKeys.mergeTokens
                ) || mergeTokens

            const [accountA, accountB] = await Promise.all([
                getAccountInfo(mergeTokens.accessTokenA),
                getAccountInfo(mergeTokens.accessTokenB),
            ])

            if (
                'errors' in accountA ||
                'errors' in accountB ||
                tokenDetailsA?.errors ||
                tokenDetailsB?.errors
            ) {
                navigate(accountLinkErrorUrl, {
                    state: {
                        error: (accountA as RegularRequestRetryError).errors
                            ? accountA
                            : accountB,
                    },
                })
                return
            }

            setState({
                accountA: { ...accountA, platform: tokenDetailsA.platform },
                accountB: { ...accountB, platform: tokenDetailsB.platform },
                mergeAccessTokenA: mergeTokens.accessTokenA,
                mergeAccessTokenB: mergeTokens.accessTokenB,
                showAccountDetails: true,
            })
        }
        fetchData()
    }, [t, location])

    const handleMerge = async (event: React.MouseEvent): Promise<void> => {
        event.preventDefault()
        setLoading(true)
        const result = await mergeAccounts({
            source,
            token_a: state.mergeAccessTokenA,
            token_b: state.mergeAccessTokenB,
            game_profiles: {},
        })

        if (!result.errors) {
            if (result.access_token && result.refresh_token) {
                await refreshUserData(result)
            }
            cleanSessionStorage()

            navigate(accountUrl, { state: { merged: true } })
        } else {
            navigate(accountLinkErrorUrl, {
                state: {
                    error: {
                        user_id_a: state.accountA?.user_id,
                        user_id_b: state.accountB?.user_id,
                        result,
                    },
                },
            })
        }
        setLoading(false)
    }

    const MergeButton = styled(LoadingButton)`
        margin-top: ${theme.spacing(5)};
        text-transform: Capitalize;
    `
    const BackButton = styled(Button)`
        margin-top: ${theme.spacing(1)};
        text-transform: Capitalize;
    `
    return (
        <CardWithLogoXS>
            <Typography component="h1" variant="h5">
                {t('Confirm Account Linking')}
            </Typography>
            <Box sx={{ pt: '16px' }} textAlign="center">
                <Typography variant="body1">
                    {t(
                        'Would you like to link these accounts into a single CA account? You should not lose any progress or redeemed content in this process. This cannot be undone.'
                    )}
                </Typography>
            </Box>
            {state.showAccountDetails && (
                <Box>
                    {state.accountB?.email && !state.accountA?.email && (
                        <AccountMergeDetails account={state.accountB} />
                    )}
                    <AccountMergeDetails account={state.accountA} />
                    {(!state.accountB?.email || state.accountA?.email) && (
                        <AccountMergeDetails account={state.accountB} />
                    )}
                    <MergeButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        loading={loading}
                        fullWidth
                        onClick={(event: React.MouseEvent): Promise<void> =>
                            handleMerge(event)
                        }
                    >
                        {t('Confirm Linking')}
                    </MergeButton>
                    <BackButton
                        type="button"
                        color="primary"
                        variant="text"
                        fullWidth
                        onClick={(): Promise<void> => navigate(accountUrl)}
                    >
                        {t('Cancel')}
                    </BackButton>
                </Box>
            )}
        </CardWithLogoXS>
    )
}

export default AccountMerge
